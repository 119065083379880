const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    
    {
        label: "Superviser Dashboard",
        icon: "mdi mdi-view-dashboard",
        isHasArrow: true,
        url: "/SuperviserDashboard",
    },
    {
        label: "Therapist Dashboard",
        icon: "mdi mdi-view-dashboard",
        isHasArrow: true,
        url: "/TherapistDashboard",
    },
    
    {
        label: "Scheduler",
        icon: "mdi mdi-calendar",
        isHasArrow: true,
        url: "/Scheduler",
    },
    {
        label: "Track Hours",
        icon: "mdi mdi-clock-outline",
        isHasArrow: true,
        url: "/TrackHours",
    },
    {
        label: "Agendas",
        icon: "mdi mdi-book-outline",
        isHasArrow: true,
        url: "/Agendas",
    },
    {
        label: "Incident Reporting",
        icon: "mdi mdi-alert-outline",
        isHasArrow: true,
        url: "/IncidentReporting",
    },
    {
        label: "Message",
        icon: "mdi mdi-message-outline",
        isHasArrow: true,
        url: "/Message",
    },
    {
        label: "Leave Request",
        icon: "mdi mdi-leaf",
        isHasArrow: true,
        url: "/LeaveRequest",
    },

    {
        label: "Payment Method",
        icon: "mdi mdi-message-outline",
        isHasArrow: true,
        url: "/PaymentMethod",
    },


    
   
    {
        label: "Setting",
        isMainMenu: true,
    },
    // {
    //     label: "User",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/User",
    // },
    // {
    //     label: "Therapist",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/Therapist",
    // },
    // {
    //     label: "Supervisor",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/Supervisor",
    // },
    // {
    //     label: "Sites",
    //     icon: "mdi mdi-calendar-outline",
    //     isHasArrow: true,
    //     url: "/SitePage",
    // },

    {
        label: "User",
        icon: "mdi mdi-account",
        isHasArrow: true,
        url: "/User",
    },
    {
        label: "Therapist",
        icon: "mdi mdi-account-heart",
        isHasArrow: true,
        url: "/Therapist",
    },
    {
        label: "Supervisor",
        icon: "mdi mdi-account-tie",
        isHasArrow: true,
        url: "/Supervisor",
    },
    {
        label: "Sites",
        icon: "mdi mdi-earth",
        isHasArrow: true,
        url: "/SitePage",
    },
    // {
    //     label: "VideoComponent",
    //     icon: "mdi mdi-earth",
    //     isHasArrow: true,
    //     url: "/VideoComponent",
    // },
    
    // {
    //     label: "Education Details",
    //     icon: "mdi mdi-school-outline",
    //     isHasArrow: true,
    //     url: "/EducationDetails",
    // },
    
   
    
    
    
]
export default SidebarData;