import React from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios'

export const CheckoutForm = () => {
 
  return (
    <CardElement />
     
  );
};

export default CheckoutForm;
