// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography,NativeSelect } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'



const LeaveRequest = () => {
  document.title = "LeaveRequest | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();

  const columns = [
    {
      field: 'lastName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Last Name',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openLeaveRequestRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.lastName}`}</Button>
      )
    },
   
    {
      field: 'firstName',
      headerClassName: 'super-app-theme--header',
      headerName: 'First Name',
      flex: 0.5
    },
   
    
    
    {
      field: 'leaveStartDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'StartDate',
      flex: 0.3
    },
    {
        field: 'leaveEndDate',
        headerClassName: 'super-app-theme--header',
        headerName: 'EndDate',
        flex: 0.3
      },
    
    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                     
                    inactive: true,
                      id: cell.row.id
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   
  ]
  const [selectedValue, setSelectedValue] = useState('')
  const handleChange = event => {
    const newValue = event.target.value
    setSelectedValue(newValue)
    applyRefresh(newValue)
    console.log("Selected value my kya ha : " ,  newValue)
  }
  const [rows, setRows] = useState(null)
  // useEffect(() => {
  //   setLoading(true)

  //   axios
  //     .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReports`, { headers })
  //     .then(response => {
  //       setLoading(false)
  //       setRows(response)
  //     })
  //     .catch(error => {})

  // }, [refresh])

  const onFilterSearch = event => {
    setLoading(true)
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

  }
  useEffect(() => {
    setLoading(true)
    
 
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequest?requestString=${selectedValue}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})
    

  }, [refresh,selectedValue])
 
 
 
  const openLeaveRequestRegistration = (event, id = 0) => {
    navigate('/LeaveRegistration', { state: { LeaveRequestid: id } })
  }

  return (
    <div className="page-content">
    <Container >
      <Grid container spacing={1}>
        <Grid item xs={0.5} sm={0.5} xl={0.5}>
        <Typography style={{color:'black',fontSize:'18px',marginTop:'6px'}}>
         Type:
      </Typography>
        </Grid>
        <Grid item xs={7.5} sm={7.5} xl={7.5}>
        <NativeSelect
                  onChange={handleChange}
                
                  value={selectedValue}
                  
                
                  style={{
                    marginLeft: '7px',
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    fontWeight: '400',
                    lineHeight: '22px',
                    letterSpacing: '0px',
                    textAlign: 'left',
                    borderBottom: 'none',
                    textDecoration: 'none',width:'300px'
                  }}
                >
                
                  {/* <option value='All'>All</option> */}
                  <option value='all'>All</option>
                  <option value='pending'>Pending</option>
                  <option value='approved'>Approved</option>

                </NativeSelect>
        </Grid>
        
        <Grid  item xs={2} sm={2} xl={2}>
        <Button  className="Button" onClick={onFilterSearch} variant='contained' style={{fontSize:'14px',fontWeight:'bold',
          float:'right'}}>
         <CachedOutlinedIcon
        fontSize='small'
        style={{
          color: 'white',
          paddingRight: '4px',
          paddingBottom: '2px',
          
        }}
      ></CachedOutlinedIcon>
          Refresh
        </Button>
        </Grid>
        <Grid  item xs={2} sm={2} xl={2}>
        <Button  className="Button" onClick={openLeaveRequestRegistration} variant='contained' style={{fontSize:'14px',fontWeight:'bold',
          }}>
        <AddOutlinedIcon
        fontSize='small'
        style={{
          color: 'white',
          paddingRight: '4px',
          paddingBottom: '2px',
          
        }}
      ></AddOutlinedIcon>
          Add New
        </Button>
        </Grid>
       <Grid item xs={12} sm={12} xl={12}>
       <Box mb={0} style={{ marginTop: 0 }}>
      {isLoading ? (
    <div style={{ margin:'5rem', textAlign:'center' }}>
       <CircularProgress /> 
    </div>
  ) : (
        <div mb={0} style={{ height: 400, width: '100%' }}>
          {rows === null || rows.length === 0 ? ("No Record Found") : (
            <DataGrid
              rows={rows}
              classes={gridClasses}
              rowHeight={20}
              columns={columns}
              getRowSpacing={getRowSpacing}
              //checkboxSelection
              fullWidth
              sx={gridRowStyle}
              pagination
              autoPageSize
            />
          )}
        </div>
        )}
      </Box>
       </Grid>
      </Grid>
    </Container>
  </div>
      
     
  )
}

export default LeaveRequest
