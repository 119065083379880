import { useState, useEffect, useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';



import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';




const LeaveRegistration = props => {
  const fields = [
    "id",
    "practiceId",
    "supervisorId",
    "firstName",
    "lastName",
    "leavePurpose",
    "additionalInfo",
    "pendingApproval",
    "leaveStartDate",
    "leaveEndDate",
    "therapistsId",
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    supervisorId: 0,
    therapistsId: 0,
    firstName: "",
    lastName: "",
    leavePurpose: "",
    additionalInfo: "",
    pendingApproval: true,
    leaveStartDate: "",
    leaveEndDate: "",

  };

  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();


  const location = useLocation();
  const LeaveRequestid = location.state.LeaveRequestid;

  const [leaveStartDate, setleaveStartDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [leaveEndDate, setleaveEndDate] = React.useState(Moment().format('YYYY-MM-DD'))


  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  const UserName = localStorage.getItem("UserName")
  console.log('UserType', UserType)
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    firstName: Yup.string().required("First Name is required"),

    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [approve, setapprove] = useState(false);

  const onSubmit = data => {

    let postData = data;
    setSubmitting(true);
    if (UserType == 'Therapist') {
      postData.pendingApproval = true
    }
    else {
      postData.approved = true

    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/LeaveRequest");
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  const onSubmitreject = data => {

    let postData = data;
    setSubmitting(true);
    if (UserType == 'Therapist') {
      postData.pendingApproval = true
    }
    else {
      postData.rejected = true

    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/LeaveRequest/addLeaveRequest`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              navigate("/LeaveRequest");
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };



  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/LeaveRequest/getLeaveRequestById?id=${LeaveRequestid}`, {
        headers
      })
      .then(response => {
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.leaveStartDate).format('YYYY-MM-DD')
          setleaveStartDate(dateYMD)
          var dateYMD = Moment(response.leaveEndDate).format('YYYY-MM-DD')
          setleaveEndDate(dateYMD)
          setapprove(response.approved)

        });

      })
      .catch(error => { });
  }, []);

  function handleCancel() {
    navigate("/LeaveRequest");
  }

  const style = {
    position: 'absolute',
    top: '51%',
    left: '50%',
    padding: '7px',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 500,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [TherapistID, setTherapistID] = useState("");
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);

      })
      .catch(error => { });

  }, []);

  return (
    <React.Fragment>
      <Grid container>
        <Box sx={style}>

          <Grid container spacing={1}>

            <Grid item xs={12} sm={12} xl={12} >
              <Headroom >
                <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky' }}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop: '-10px',
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize: '1rem'

                          }}
                        >
                          Leave Request

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCancel}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float: 'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>
              </Headroom>
            </Grid>


            {LeaveRequestid <= 0 ? (

              <>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Typography style={{ fontSize: '14px' }}>
                    Last Name
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Typography style={{ fontSize: '14px' }}>
                    First Name
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Controller
                    name="lastName"
                    {...register("lastName")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="please enter last name"
                        {...field}
                        fullWidth
                        error={errors.lastName}
                      />}
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.lastName?.message}</p>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={6}>
                  <Controller
                    name="firstName"
                    {...register("firstName")}
                    control={control}
                    render={({ field }) =>
                      <TextField
                        size="small"
                        placeholder="please enter first name"
                        {...field}
                        fullWidth
                        error={errors.firstName}
                      />}
                  />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.firstName?.message}</p>
                </Grid>
<Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

              </>
            ) : ''}

            {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Typography style={{ fontSize: '14px' }}>
                Last Name
              </Typography>
            </Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Typography style={{ fontSize: '14px' }}>
                First Name
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Controller
                name="lastName"
                {...register("lastName")}
                control={control}
                render={({ field }) =>
                  <TextField
                    size="small"
                    placeholder="please enter last name"
                    {...field}
                    fullWidth
                    error={errors.lastName}
                  />}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.lastName?.message}</p>
            </Grid>
            <Grid item xs={5.5} sm={5.5} xl={6}>
              <Controller
                name="firstName"
                {...register("firstName")}
                control={control}
                render={({ field }) =>
                  <TextField
                    size="small"
                    placeholder="please enter first name"
                    {...field}
                    fullWidth
                    error={errors.firstName}
                  />}
              />
              <p style={{ color: 'red', fontSize: '14px' }}>{errors.firstName?.message}</p>
            </Grid> */}
            {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11} sm={11} xl={11}>
              <Typography style={{ fontSize: '14px' }}>
                Therapist
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

            {UserType == 'Therapist' ? (
              <>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                  <Controller
                    name="therapistsId"
                    {...register("therapistsId")}
                    control={control}
                    render={({ field }) => {
                      const selectedValue = TherapistList.find(option => option.label === UserName);
                      console.log("UserName:", UserName);
                      console.log("TherapistList:", TherapistList);
                      console.log("Selected Value:", selectedValue);

                      if (selectedValue) {
                        console.log("Match found:", selectedValue);
                      } else {
                        console.log("No match found");
                      }
                      return (
                        <ReactSelect
                          {...field}
                          fullWidth
                          value={selectedValue}
                          isDisabled={true}
                          onChange={e => {
                            setValue("therapistsId", e.value);
                            setTherapistID(e.value);
                          }}
                          options={TherapistList}
                        />
                      );
                    }}
                  />

                </Grid>
              </>) :
              (
                <>
                  <Grid item xs={5.5} sm={5.5} xl={5.5}>
                    <Controller
                      name="therapistsId"
                      // {...register("userId")}
                      control={control}
                      render={({ field }) =>
                        <ReactSelect
                          {...field}
                          fullWidth
                          value={TherapistList.filter(function (option) {
                            return (
                              option.value === getValues("therapistsId")
                            );
                          })}
                          onChange={e => {
                            setValue("therapistsId", e.value);
                            setTherapistID(e.value);


                          }}
                          options={TherapistList}
                        />

                      }
                    />
                  </Grid>


                </>)}

            <Grid item xs={5.5} sm={5.5} xl={5.5}> </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Typography style={{ fontSize: '14px' }}>
                Leave Start Date
              </Typography>
            </Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Typography style={{ fontSize: '14px' }}>
                Leave End Date
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Controller
                name="leaveStartDate"
                {...register("leaveStartDate")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="date"
                    size="small"

                    {...field}
                    value={leaveStartDate ? leaveStartDate : ''}
                    onChange={e => {
                      setValue('leaveStartDate', e.target.value)
                      setleaveStartDate(e.target.value)
                    }}
                    fullWidth
                  // error={errors.supervisorFirstName}
                  />}
              />
              {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.supervisorFirstName?.message}</p> */}
            </Grid>
            <Grid item xs={5.5} sm={5.5} xl={5.5}>
              <Controller
                name="leaveEndDate"
                {...register("leaveEndDate")}
                control={control}
                render={({ field }) =>
                  <TextField
                    type="date"
                    size="small"

                    {...field}
                    value={leaveEndDate}
                    onChange={e => {
                      setValue('leaveEndDate', e.target.value)
                      setleaveEndDate(e.target.value)
                    }}
                    fullWidth
                  // error={errors.supervisorFirstName}
                  />}
              />
              {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.supervisorFirstName?.message}</p> */}
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11.5} sm={11.5} xl={11.5}>
              <Typography style={{ fontSize: '14px' }}>
                Purpose for leave
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={11} sm={11} xl={11}>
              <Controller
                name='leavePurpose'
                {...register('leavePurpose')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Leave Purpose'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                      //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
            <Grid item xs={11.5} sm={11.5} xl={11.5}>
              <Typography style={{ fontSize: '14px' }}>
                Additional Information
              </Typography>
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

            <Grid item xs={11} sm={11} xl={11}>
              <Controller
                name='additionalInfo'
                {...register('additionalInfo')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Additional Info'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                      //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>


            {UserType == 'Therapist' ? (<>
              {approve == true ? (<>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                  <Button
                    className="Button"
                    // onClick={handleSubmit(onSubmitagenda)}
                    disabled={submitting}

                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                    type="submit"
                  >

                    Approved
                    <TaskAltIcon
                      style={{
                        color: "green",
                        // cursor: "not-allowed",
                      }}
                    >

                    </TaskAltIcon>
                  </Button>
                </Grid>

                <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                  <Button
                    className="Button"
                    // onClick={handleSubmit(onSubmitreject)}
                    // disabled={submitting}
                    fullWidth
                    style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  // type="submit"
                  >
                    Reject
                  </Button>
                </Grid>

              </>) : (<>

                {LeaveRequestid > 0 ? (<> 
                 <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                  <Grid item xs={3.6} sm={3.6} xl={3.6}  style={{ marginTop: '10px' }} >
                    <Button
                      className="Button"
                      onClick={handleSubmit(onSubmit)}
                      disabled={submitting}
                      fullWidth
                      style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '12px', fontWeight: 'bold' }}
                      type="submit"
                    >
                      Send For Approved
                      <TaskAltIcon
                        style={{
                          color: "red",
                          // cursor: "not-allowed",
                        }}
                      >

                      </TaskAltIcon>
                    </Button>
                  </Grid></>) : (<>
                    {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}

                    <Grid item xs={3.6} sm={3.6} xl={3.6}  style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '12px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Save

                      </Button>
                    </Grid>
                  </>)}



              </>)}


            </>) : (
              <>
                {LeaveRequestid > 0 ? (

                  <>


                    <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Approve

                        <TaskAltIcon
                    style={{
                      color: "green",
                      // cursor: "not-allowed",
                    }}
                  >

                  </TaskAltIcon>
                      </Button>
                    </Grid>
                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmitreject)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Reject
                      </Button>
                    </Grid>

                  </>
                ) : (<>
                  <>


                    <Grid item xs={2.2} sm={2.2} xl={2.2}></Grid>

                    <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }} >
                      <Button
                        className="Button"
                        onClick={handleSubmit(onSubmit)}
                        disabled={submitting}
                        fullWidth
                        style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>


                  </>


                </>)}
              </>


            )}

            <Grid item xs={3.6} sm={3.6} xl={3.6} style={{ marginTop: '10px' }}>
              <Button
                className="Button"
                onClick={handleCancel}
                disabled={submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

              >

                Cancel
              </Button>
            </Grid>


            {/* <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid> */}

          </Grid>



        </Box>
      </Grid>
    </React.Fragment>
  );
};
export default LeaveRegistration;
