import React, { useEffect, useRef, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import { Root, customStyles } from "app/shared-components/CustomConstants";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import DeleteIcon from '@mui/icons-material/Delete'


import axios from "axios";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import * as yup from "yup";
// import _ from "@lodash";
import moment from "moment";
import ReactSelect from "react-select";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import { motion } from "framer-motion";
// import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
// import CalendarViewMenu from "./CalendarViewMenu";
import { useTheme } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Chip from "@mui/material/Chip";
import InfoIcon from "@mui/icons-material/Info";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import AppointmentCreate from "../AppointmentCreate";

const validationSchema = yup.object().shape({
  // title: yup.string().required('You must enter a title'),
});

const Scheduler= () => {
  document.title = "Scheduler | Maevisory";
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const username = localStorage.getItem('UserName')
  const UserType = localStorage.getItem('User_Type')
  const Userid = localStorage.getItem('userid')


console.log('username',username)
  const customStyles = {
    marginTop: "1px",
    height: "15px",
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isValid, dirtyFields, errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    // defaultValues: defValues,
  });
  const [refresh, setRefresh] = useState(false)
  const [applyRefresh, setapplyRefresh] = useState(false);
  const applyRefreshh = () =>  {
    setapplyRefresh(!applyRefresh)
  }
  const errorMesage = {
    color: "red",
    fontSize: "13px",
    marign: "0px",
  };
  const viewNamesObj = {
    dayGridMonth: {
      title: "Month",
      icon: "view_module",
    },
    timeGridWeek: {
      title: "Week",
      icon: "view_week",
    },
    timeGridDay: {
      title: "Day",
      icon: "view_agenda",
      allDays: "false"
    },
  };
  const modalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    zIndex:300000
  };
  const zipCodeListStyle = {
    cursor: "pointer",
    hover: {
      background: "#3b3b3b !important",
      color: "rgba(5, 152, 236, 0.637) !important",
    },
    color: "green",
    selection: {
      background: "#D7624E",
      color: "white",
    },
  };
  const [SuperviseID, setSuperviseID] = useState(Userid);
  const [TherapistID, setTherapistID] = useState(Userid);

  

  const [SuperviseIDmodal, setSuperviseIDmodal] = useState("");

  
  const [SuperviseList, setSuperviseList] = useState([]);
  const [SuperviseListmodal, setSuperviseListmodal] = useState([]);
  const [TherapistList, setTherapistList] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const calendarRef = useRef();
  const [currentDate, setCurrentDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState([]);
  console.log('events',events)
  const [isEventDialogOpen, setEventDialogOpen] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeValues, setEmployeeValues] = useState([]);
  const [client, setClient] = useState(false);
  const [clientSearchText, setClientSearchText] = useState("");
  const [clientData, setClientData] = useState([]);
  const [clientID, setClientID] = useState("");
  const [clientNamee, setClientName] = useState("");
  const [clientSelected, setClientSelected] = useState(false);
  const [employee, setEmployee] = useState(false);
  const [employeeSearchText, setEmployeeSearchText] = useState("");
  const [employeeData, setEmployeeData] = useState([]);
  const [employeeID, setEmployeeID] = useState("");
  const [employeetNamee, setEmployeeName] = useState("");
  const [employeeSelected, setEmployeeSelected] = useState(false);
  const [AgencyList, setAgencyList] = useState([]);
  const [sTime, setSTime] = useState(null);
  const [eTime, setETime] = useState(null);
  const [recurringEndDateTime, setRecurringEndDateTime] = useState(null);
  const [endDateTime, setEndDateTime] = useState(null);
  const [startDateTime, setStartDateTime] = useState(null);
  const [startDateRange, setStartDateRange] = useState(null);
  const [endDateRange, setEndDateRange] = useState(null);
  const [durationError, setdurationError] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorE2, setAnchorE2] = useState(null);
  const [Totalcount, setTotalcount] = useState(0);
  const [isRecurring, setIsRecurring] = useState(false);
  const [selectedDays, setSelectedDays] = useState({
    Mon: false,
    Tue: false,
    Wed: false,
    Thu: false,
    Fri: false,
    Sat: false,
    Sun: false,
  });
  const open = Boolean(anchorEl);
  const openOnhover = Boolean(anchorE2);

  // console.log("Selected Days keya hy", selectedDays);
  // console.log("employee List ya hy", employeeList);

  // handles
  const [appid, setappid] = useState(0);
  console.log('checktest',appid)
 
  const handleDateSelect = (selectInfo) => {
    
    console.log("Selected Days keya hy",selectInfo );
const { start, end } = selectInfo;
    // Extract and format time
    const startTime = moment(start, "HH:mm").format("HH:mm:ss");
    const endTime = moment(end, "HH:mm").format("HH:mm:ss");

    setCurrentDate(selectInfo);
    setSTime(startTime);
    setETime(endTime);
    setEventDialogOpen(true);
    setappid(0)
  };
  const handleEventClick = (clickInfo) => {
    console.log('check',clickInfo)
    setappid(clickInfo.event._def.publicId)
    setSelectedEvent(clickInfo.event);
    setEventDialogOpen(true);
  };
  const handleClickhover = (event) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClosehover = () => {
    setAnchorE2(null);
  };
  

  const handleDates = (rangeInfo) => {
    console.log(rangeInfo, "range info ya rahi");

    const { start, end } = rangeInfo;

    const startDateRangeFormat = moment(start, "YYYY/MM/DD").format(
      "YYYY/MM/DD"
    );
    const endDateRangeFormat = moment(end, "YYYY/MM/DD")
      .subtract(1, "seconds")
      .format("YYYY/MM/DD");

    setStartDateRange(startDateRangeFormat);
    setEndDateRange(endDateRangeFormat);
    setCurrentDate(rangeInfo);
  };

  const handleEventAdd = (addInfo) => {
    // Handle event add logic if needed
    console.log("Added event:", addInfo.event);
  };

  const handleEventChange = (changeInfo) => {
    // Handle event change logic if needed
    console.log("Changed event:", changeInfo.event);
  };

  const closeEventDialog = () => {
    setEventDialogOpen(false);
  };

  const handleAddButtonClick = () => {
    const selectedClient = getValues("client");
    const selectedEmployee = getValues("employee");
    const selectedReason = getValues("reason");

    // Log the selected values to the console
    console.log("Selected Client:", selectedClient);
    console.log("Selected Employee:", selectedEmployee);
    console.log("Selected Reason:", selectedReason);
    console.log("Selected Start Time:", startDateTime);
    console.log("Selected End Time:", endDateTime);

    // Add your logic to send the data or perform any other actions
  };

  const theme = useTheme();
  const calendarApi = calendarRef.current?.getApi();

  const handlePrevClick = () => {
    if (calendarApi) {
      calendarApi.prev();
    }
  };

  const handleNextClick = () => {
    if (calendarApi) {
      calendarApi.next();
    }
  };

  const handleTodayClick = () => {
    if (calendarApi) {
      calendarApi.today();
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeView = (name) => {
    // console.log("changeview called ", name);
    if (calendarApi && calendarApi.changeView) {
      calendarApi.changeView(name);
      handleClose();
    }
  };

  
  ///api's .............


  useEffect(() => {
    if (startDateRange !== null && endDateRange !== null) {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/Appointment/getAppointments?therapistId=${TherapistID}&supervisorId=${SuperviseID} `,
          {
            headers,
          }
        )
        .then((response) => {
          // console.log("Get visit with date response", response.data);

          var res = response;
          var arr = [];
          console.log('test',response)
          res.forEach((e) => {
            arr.push({
              id: e.id,
              title: e.therapistName,
              SuperviseName: e.supervisorName,
              reason: e.appointmentReason,
              
            
              // Name:'qasim',
              start: new Date(e.startTime).toISOString(),
              end: new Date(e.endTime).toISOString(),
            });
          });

          // console.log('arrr ma keya data ata hy', arr)
          setEvents(arr);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  }, [appid, startDateRange, endDateRange,SuperviseID,TherapistID,applyRefresh]);

  ///Client search Item

 
  
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Supervisor'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setSuperviseList(prac);
        setSuperviseListmodal(prac);
        
      })
      .catch(error => {});

  }, [applyRefresh]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);
        
        
      })
      .catch(error => {});

  }, [applyRefresh]);
 
  const style = {
   
    // width: 500,
    height: 50,
    bgcolor: '#56B298',
    border: '2px solid #EA7772',
    // boxShadow: 24,
  
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#377562',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  
  }

  return (
    <>
     <div className="page-content">
     <Grid container spacing={1}>
      <Grid item xs={2} sm={2} xl={2}>
      <Typography style={{fontSize:'14px',fontWeight:'bold',color:'black'}}>
                  {currentDate?.view.title}
                </Typography>
      </Grid>
      <Grid item xs={0.5} sm={0.5} xl={0.5}>
      <Tooltip title="Previous">
                  <ArrowBackIosIcon onClick={handlePrevClick} style={{fontSize:'medium'}} >
                  {theme.direction === "ltr"
                        ? "heroicons-solid:chevron-left"
                        : "heroicons-solid:chevron-right"}
                  </ArrowBackIosIcon>
                </Tooltip>
      </Grid>
      <Grid item xs={0.5} sm={0.5} xl={0.5}>
      <Tooltip title="Next">
                  <NavigateNextIcon onClick={handleNextClick} >
                  {theme.direction === "ltr"
                        ? "heroicons-solid:chevron-right"
                        : "heroicons-solid:chevron-left"}
                  </NavigateNextIcon>
                </Tooltip>
      </Grid>
      <Grid item xs={0.5} sm={0.5} xl={0.5}>
      <Tooltip title="Today">
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, transition: { delay: 0.3 } }}
                  >
                    <CalendarMonthIcon
                      aria-label="today"
                      onClick={handleTodayClick}
                      size="large"
                    >
                    </CalendarMonthIcon>
                  </motion.div>
                </Tooltip>
      </Grid>
      <Grid item xs={1} sm={1} xl={1}>
 <Typography style={{fontSize:'14px',fontWeight:'bold',color:'black'}}>
                Supervisor
              </Typography>
      </Grid>
      {UserType == 'Therapist' || UserType == 'Supervisor' ?(<>
        <Grid item xs={2.5} sm={2.5} xl={2.5}>
      <Controller
  name="userId"
  control={control}
 
  render={({ field }) => (
   
    <ReactSelect
      {...field}
      fullWidth
      // value={SuperviseList.find((option) => option.value === field.value)}
      value={SuperviseList.find(option => option.label === username)}
      isDisabled={true}
      onChange={(selectedOption) => {
        field.onChange(selectedOption.value); // Update the form value
        setSuperviseID(selectedOption.value);
      }}
      options={SuperviseList}
      styles={{
        // control: (provided) => ({
        //   ...provided,
        //   zIndex: 9999, // Set a high z-index value for the control
        // }),
        menu: (provided) => ({
          ...provided,
          zIndex: 1000, // Set a high z-index value for the dropdown menu
        }),
      }}
    />
  )}
/>
    
      </Grid>
      </>):(<>
        <Grid item xs={2.5} sm={2.5} xl={2.5}>
      <Controller
  name="userId"
  control={control}
 
  render={({ field }) => (
   
    <ReactSelect
      {...field}
      fullWidth
      // value={SuperviseList.find((option) => option.value === field.value)}
      value={SuperviseList.find(option => option.label === username)}

      // isDisabled={true}
      onChange={(selectedOption) => {
        field.onChange(selectedOption.value); // Update the form value
        setSuperviseID(selectedOption.value);
      }}
      options={SuperviseList}
      styles={{
      
        menu: (provided) => ({
          ...provided,
          zIndex: 1000, // Set a high z-index value for the dropdown menu
        }),
      }}
    />
  )}
/>
 
      </Grid>
      
      </>)}
    

      <Grid item xs={1} sm={1} xl={1}>
      <Typography style={{fontSize:'14px',fontWeight:'bold',color:'black'}}>
      Therapist
                    </Typography>
      </Grid>
      {UserType == 'Therapist'?(<>
        <Grid item xs={2.5} sm={2.5} xl={2.5} >

          <Controller
          name="userId"
          control={control}
          render={({ field }) => (
            <ReactSelect

              {...field}
              fullWidth
              value={TherapistList.find(option => option.label === username)}
              isDisabled={true}
              //  value={TherapistList.find((option) => option.value === field.value)}
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value); // Update the form value
                setTherapistID(selectedOption.value);
              }}
              options={TherapistList}
              styles={{
                //  control: (provided) => ({
                //    ...provided,
                //    zIndex: 9999, // Set a high z-index value for the control
                //  }),
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000, // Set a high z-index value for the dropdown menu
                }),
              }}
              
            />
          )}
          />
</Grid>
      
      </>):(<>
        <Grid item xs={2.5} sm={2.5} xl={2.5} >
        <Controller
          name="userId"
          control={control}
          render={({ field }) => (
            <ReactSelect

              {...field}
              fullWidth
              value={TherapistList.find(option => option.label === username)}
              // value={TherapistList.find((option) => option.value === field.value)}
              onChange={(selectedOption) => {
                field.onChange(selectedOption.value); // Update the form value
                setTherapistID(selectedOption.value);
              }}
              options={TherapistList}
              styles={{
                
                menu: (provided) => ({
                  ...provided,
                  zIndex: 1000, // Set a high z-index value for the dropdown menu
                }),
              }}
              
            />
          )}
          />
          </Grid>
      
      </>)}
     
 
      
    
      

      <Grid item xs={1.5} sm={1.5} xl={1.5}>
      <motion.div
              style={customStyles}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3 } }}
            >
                <Button
               
                  sx={{ minWidth: 90 }}
                  className="Button"
                  id="view-select-button"
                  aria-controls="view-select-menu"
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  // variant="outlined"
                  style={{color:'white', fontWeight:'bold'}}
                  // endIcon={
                  //   <FuseSvgIcon size={16}>
                  //     heroicons-outline:chevron-down
                  //   </FuseSvgIcon>
                  // }
                >
                  {viewNamesObj[currentDate?.view.type]?.title}
                </Button>
                <Menu
                  id="view-select-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "view-select-button",
                  }}
                >
                  {Object.entries(viewNamesObj).map(([name, view]) => (
                    <MenuItem key={name} onClick={() => handleChangeView(name)}>
                      <ListItemText
                        primary={view.title}
                        style={{ width: "150px" }}
                      />
                    </MenuItem>
                  ))}
                </Menu>
             
            </motion.div>
      </Grid>
      

     </Grid>
     <Grid container spacing={1}>
      <Grid item xs={12} sm={12} xl={12} style={{marginTop:'50px'}}>
      <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={false}
            initialView="timeGridDay"
            // editable
            selectable
            selectMirror
            dayMaxEvents
            weekends
            slotDuration="00:15:00"
            snapDuration="00:15:00"
            slotMinTime="00:00:00"
            slotMaxTime="24:00:00"
            nowIndicator={true}
            showNonCurrentDates={false}
            clickToCreate={false}
            datesSet={handleDates}
            select={handleDateSelect}
            events={events}

            eventContent={(eventInfo) => {
             const isTimeGridDayView =
              eventInfo.view.type === "timeGridDay" || eventInfo.view.type === "timeGridWeek";

              return (
                <Box >
                  <Grid container spacing={1} style={{backgroundColor:'#56B298'}}>
                    <Grid item xs={12} sm={12} xl={12} >
                    <Typography style={{color:'white',textAlign:"left",fontWeight:'bold'}}>
                    {eventInfo.event.title}
                  </Typography>
                    </Grid>
                  
                    {isTimeGridDayView && (
            <Grid item xs={12} sm={12} xl={12}>
              <Typography style={{ color: 'white', textAlign: 'left', fontWeight: 'bold' }}>
                {eventInfo.event.extendedProps.reason}
              </Typography>
            </Grid>
          )}
                    

                  </Grid>
                 
                  {/* <Typography style={{color:'white'}}>
                    {eventInfo.event.title}
                    {eventInfo.event.extendedProps.SuperviseName}
                    
                  </Typography> */}
                </Box>
              );
            }}
           
            eventClick={handleEventClick}
            eventAdd={handleEventAdd}
            // onEventDoubleClick={handleEventClick}
            eventChange={handleEventChange}
            initialDate={new Date()}
            ref={calendarRef}
          />
      </Grid>
    
     </Grid>
     <Modal
        open={isEventDialogOpen}
        onClose={closeEventDialog}
        className="glassmorphic-card"
      >
        <Box
         sx={modalstyle}
        >
        <AppointmentCreate
        CloseModal={closeEventDialog}
        appid={appid}
        sTime={sTime}
        eTime={eTime}
        SuperviseID={SuperviseID}
        applyRefresh={applyRefreshh}
        TherapistID={TherapistID}
        ></AppointmentCreate>
          {/* <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h5" className="bold-text">
              Event
            </Typography>
            <IconButton
              onClick={closeEventDialog}
              className="cancel-icon-button "
            >
              <CancelTwoToneIcon />
            </IconButton>
          </Box> */}
          {/* <Grid item xs={12} marginBottom={"15px"}>
            <Typography variant="body1" className="bold-text">
              Client
            </Typography>
            <Controller
              name="ClientName"
              {...register("ClientName")}
              control={control}
              render={({ field }) => (
                <TextField
                  inputProps={{
                    style: {
                      backgroundColor: "#fff",
                      color: "#9389AF",
                      borderRadius: "5px",
                      height: "21px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Client..."
                  {...field}
                  onKeyUp={(ev) => {
                    console.log("ee:", ev.target.value);
                    setClientSearchText(ev.target.value);
                    setClientData([]);

                  
                    if (
                      ev.target.value !== null &&
                      ev.target.value !== "" &&
                      ev.target.value.length > 0
                    ) {
                      onChangeHandleClientName(ev.target.value);
                    } else {
                      setClientSelected(false);
                      setClient(false);

                   
                    }
                  }}
                 
                />
              )}
              
            />

            <p style={errorMesage}>{errors.clientName?.message}</p>

            {client ? (
              <div
                className="col-xs-6"
                style={{
                  height: 150,
                  overflowY: "scroll",
                  padding: "14px 16px",
                  fontSize: "0.875rem",
                  color: "#67748e",
                  borderRadius: "5px",
                  background: "#fff",
                  textAlign: "justify",
                  textJustify: "inter-word",
                  position: "absolute",
                  zIndex: "99",
                  width: "560px",
                  border: "1px solid #DCDBDD",
                }}
              >
                {client ? (
                  clientData.length > 0 ? (
                    clientData.map((s, index) => {
                      var clientName = `${s.lastName}, ${s.firstName}`;
                      var patLower = clientName.toLowerCase();
                      var i = patLower.indexOf(clientSearchText);

                      var p1 = clientName.substring(0, i);

                      var p = clientName.substring(
                        i,
                        clientSearchText.length + i
                      );

                      var p2 = clientName.substring(
                        i + p.length,
                        patLower.length
                      );

                      return (
                        <p
                          style={zipCodeListStyle}
                          onClick={(ev) => {
                            setValue(
                              "clientName",
                              `${clientData[index].lastName}, ${clientData[index].firstName}`
                            );

                            setValue("clientID", clientData[index].id);
                            setClientID(clientData[index].id);
                            setClientData([]);
                            setClientSelected(true);
                            setClient(false);
                            setClientName(
                              `${clientData[index].lastName}, ${clientData[index].firstName}`
                            );
                          }}
                          onBlur={(ev) => {
                            setClientData([]);
                            setClient(false);
                          }}
                          onChange={(ev) => {
                            setClientData([]);
                            setClientSelected(false);
                            setClient(false);
                            setClientName("");
                          }}
                        >
                          <span>{p1}</span>
                          <span style={{ backgroundColor: "yellow" }}>{p}</span>
                          <span>{p2}</span>
                          <Divider variant="hard" />
                        </p>
                      );
                    })
                  ) : (
                    <Typography>No Record found</Typography>
                  )
                ) : null}
              </div>
            ) : (
              ""
            )}
        
          </Grid> */}
          {/* <Grid item xs={12} marginBottom={"15px"}>
            <Typography variant="body1" className="bold-text">
              Employee
            </Typography>
            <Controller
              name="EmployeeName"
              {...register("EmployeeName")}
              control={control}
              render={({ field }) => (
                <TextField
                  inputProps={{
                    style: {
                      backgroundColor: "#fff",
                      color: "#9389AF",
                      borderRadius: "5px",
                      height: "21px",
                    },
                  }}
                  size="small"
                  fullWidth
                  placeholder="Employee..."
                  {...field}
                  onKeyUp={(ev) => {
                    console.log("ee:", ev.target.value);
                    setEmployeeSearchText(ev.target.value);
                    setEmployeeData([]);

                  
                    if (
                      ev.target.value !== null &&
                      ev.target.value !== "" &&
                      ev.target.value.length > 0
                    ) {
                      onChangeHandleEmployeeName(ev.target.value);
                    } else {
                      setEmployeeSelected(false);
                      setEmployee(false);

                     
                    }
                  }}
                 
                />
              )}
           
            />

            <p style={errorMesage}>{errors.employeeName?.message}</p>

            {employee ? (
              <div
                className="col-xs-6"
                style={{
                  height: 150,
                  overflowY: "scroll",
                  padding: "14px 16px",
                  fontSize: "0.875rem",
                  color: "#67748e",
                  borderRadius: "5px",
                  background: "#fff",
                  textAlign: "justify",
                  textJustify: "inter-word",
                  position: "absolute",
                  zIndex: "99",
                  width: "560px",
                  border: "1px solid #DCDBDD",
                }}
              >
                {employee ? (
                  employeeData.length > 0 ? (
                    employeeData.map((s, index) => {
                      var clientName = `${s.lastName}, ${s.firstName}`;
                      var patLower = clientName.toLowerCase();
                      var i = patLower.indexOf(employeeSearchText);

                      var p1 = clientName.substring(0, i);

                      var p = clientName.substring(
                        i,
                        employeeSearchText.length + i
                      );

                      var p2 = clientName.substring(
                        i + p.length,
                        patLower.length
                      );

                      return (
                        <p
                          style={zipCodeListStyle}
                          onClick={(ev) => {
                            setValue(
                              "employeeName",
                              `${employeeData[index].lastName}, ${employeeData[index].firstName}`
                            );

                            setValue("employeeID", employeeData[index].id);
                            setEmployeeID(employeeData[index].id);
                            setEmployeeData([]);
                            setEmployeeSelected(true);
                            setEmployee(false);
                            setEmployeeName(
                              `${employeeData[index].lastName}, ${employeeData[index].firstName}`
                            );
                          }}
                          onBlur={(ev) => {
                            setEmployeeData([]);
                            setEmployee(false);
                          }}
                          onChange={(ev) => {
                            setEmployeeData([]);
                            setEmployeeSelected(false);
                            setEmployee(false);
                            setEmployeeName("");
                          }}
                        >
                          <span>{p1}</span>
                          <span style={{ backgroundColor: "yellow" }}>{p}</span>
                          <span>{p2}</span>
                          <Divider variant="hard" />
                        </p>
                      );
                    })
                  ) : (
                    <Typography>No Record found</Typography>
                  )
                ) : null}
              </div>
            ) : (
              ""
            )}
           
          </Grid> */}
          {/* <Grid container spacing={2}>
           
            <Grid item xs={6} marginBottom={"15px"}>
              <Typography variant="body1" className="bold-text">
                Start Time
              </Typography>

              <Controller
                name="start"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    className="mt-8 mb-16 w-full"
                    value={
                      startDateTime
                        ? moment(startDateTime).toDate()
                        : new Date(value)
                    }
                    onChange={(newValue) => {
                      setStartDateTime(
                        moment(newValue).format("YYYY-MM-DD HH:mm:ss")
                      );
                      onChange(newValue);
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                      },
                    }}
                  />
                )}
              />
              {durationError ? (
                <p style={errorMesage}>Duration can't be 0 minutes</p>
              ) : null}
            </Grid>
            <Grid item xs={6} marginBottom={"15px"}>
              <Typography variant="body1" className="bold-text">
                End Time
              </Typography>
              <Controller
                name="end"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                  <DateTimePicker
                    className="mt-8 mb-16 w-full"
                    value={
                      endDateTime
                        ? moment(endDateTime).toDate()
                        : new Date(value)
                    }
                    onChange={(newValue) => {
                      setEndDateTime(
                        moment(newValue).format("YYYY-MM-DD HH:mm:ss")
                      );
                      onChange(newValue);
                    }}
                    slotProps={{
                      textField: {
                        variant: "outlined",
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12}>
            <Controller
              name="recurring"
              {...register("recurring")}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={Boolean(fieldState?.error)}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isRecurring}
                        onChange={handleRecurringChange}
                      />
                    }
                    label="Select If Recurring Schedule"
                  />

                  <FormHelperText>
                    {fieldState?.error ? fieldState?.error.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid> */}
          {/* <Grid item xs={12} marginBottom={"10px"}>
            {isRecurring && (
              <div>
                <FormGroup
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: "10px",
                  }}
                >
                  {Object.keys(selectedDays).map((day) => (
                    <FormControlLabel
                      key={day}
                      control={
                        <Checkbox
                          checked={selectedDays[day]}
                          onChange={handleDayChange(day)}
                        />
                      }
                      label={day}
                    />
                  ))}
                </FormGroup>

                <Typography variant="body1" className="bold-text">
                  Select End Date
                </Typography>
                <Controller
                  name="recurringEnd"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value } }) => (
                    <DateTimePicker
                    
                      className="mt-8 mb-16 w-full"
                      value={
                        recurringEndDateTime
                          ? moment(recurringEndDateTime).toDate()
                          : new Date(value)
                      }
                      onChange={(newValue) => {
                        setRecurringEndDateTime(
                          moment(newValue).format("YYYY-MM-DD HH:mm:ss")
                        );
                        onChange(newValue);
                      }}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                        },
                      }}
                    />
                  )}
                />
              </div>
            )}
          </Grid> */}
          {/* <Grid item xs={12} marginBottom={"25px"}>
            <Typography variant="body1" className="bold-text">
              Reason
            </Typography>
            <Controller
              name="reason"
              {...register("reason")}
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth error={Boolean(fieldState?.error)}>
                  <ReactSelect
                    styles={customStyles}
                    {...field}
                    options={[{ value: "reason", label: "Reason" }]}
                    value={{
                      label: getValues("reason"),
                      value: getValues("reason"),
                    }}
                    onChange={(value) => setValue("reason", value.label)}
                    fullWidth
                    menuPosition="fixed"
                    isSearchable={true}
                    noOptionsMessage={() => "No matching options"}
                  />

                  <FormHelperText>
                    {fieldState?.error ? fieldState?.error.message : ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </Grid> */}

{/*           
          <div className="flex items-center space-x-8">
            <div className="flex flex-1" />
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAddButtonClick()}
             
            >
              Add
            </Button>
          </div> */}
        </Box>
      </Modal>
    
     </div>

     
    </>
  );
}

export default Scheduler;
