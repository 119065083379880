import React, { useEffect, useState, useRef } from 'react';
import { CardElement,useStripe, useElements } from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import { useLocation, useNavigate } from "react-router-dom";
import CheckoutForm from './CheckoutForm'; 
import * as Yup from 'yup'; // Import Yup
import axios from 'axios'
import { Form } from 'react-router-dom';

const stripePromise = loadStripe('pk_test_51OU7K2GzmgnXQM1ZzsvV9RUUBFbRKzol5julcMWC8zV8ckijoKAHbr1kBB2cwqbJuKN4kkxdomxe1fhpbNjkLDNm00DHUrBE3P');



const PaymentForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
 //const stripe = useStripe();
 let stripe = stripePromise;
 stripe = useStripe();
 const elements = useElements();

 const buttonRef = useRef(null);

 const [error, setError] = useState(null);
 const [loading, setLoading] = useState(false);
 const [formData, setFormData] = useState({
    name: '',
    email: '',
    addressLine1: '',
    city: '',
    postalCode: '',
    country: '',
    phone: ''
 });

 const token = localStorage.getItem("Token");
 const auth = `Bearer ${token}`;
 const headers = {
   Authorization: auth,
 };



 const options = {
  // passing the client secret obtained from the server
  clientSecret: '{{CLIENT_SECRET}}',
};


 // Define a Yup schema for form validation
 const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    addressLine1: Yup.string().required('Address Line 1 is required'),
    city: Yup.string().required('City is required'),
    postalCode: Yup.string().required('Postal Code is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string().required('Phone is required')  
 });

 const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
 };

 const handleSubmit = async (event) => {
  setError("");
  event.preventDefault(); 
  console.log("Form submiteeeeeeedddd");
  console.log("carrrrrddd",elements.getElement(CardElement));


    
    try {
      await validationSchema.validate(formData, { abortEarly: false });
    } catch (validationError) {
      setError(validationError.errors[0]); // Set the first validation error as the form error
      return;
    } 

     setLoading(true);

     console.log("carrrrrddd",elements.getElement(CardElement));
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        name: formData.name,       
        email: formData.email,
        address: {
          line1: formData.addressLine1,
          city: formData.city,
          postal_code: formData.postalCode,
          country: formData.country,
        },
        phone: formData.phone
      },
      
    });   
    //paymentMethod.paymentMethodId = paymentMethod.id;
    setLoading(false);
    if (error) {
      setError(error.message);
    } else {
      console.log('PaymentMethod', paymentMethod);
        // Send payment method to backend for further processing
        sendPaymentMethodToBackend(paymentMethod);

      }
 };

 const  sendPaymentMethodToBackend = async (paymentMethod) => {

  
  paymentMethod.paymentId=location.state.paymentId;
  try {
   const response = await axios.post(`${process.env.REACT_APP_API_URL}/Payment/Process`, paymentMethod,{headers});
    alert.success("Credit Card Payment processed successfully ", {
      type: "success",
      onClose: () => {
        navigate("/PaymentMethod");
      },
    }); 
  } catch (error) {
    alert.error(`Error ${error.message}`, {
      type: "error",
    });
  }
 
};

 return (
    <div style={styles.container}>
       <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
         
          <div style={styles.inputGroup}>
            <label htmlFor="name" style={styles.formLabel}>
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="email" style={styles.formLabel}>
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="addressLine1" style={styles.formLabel}>
              Address Line 1
            </label>
            <input
              type="text"
              id="addressLine1"
              name="addressLine1"
              value={formData.addressLine1}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="city" style={styles.formLabel}>
              City
            </label>
            <input
              type="text"
              id="city"
              name="city"
              value={formData.city}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="postalCode" style={styles.formLabel}>
              Postal Code
            </label>
            <input
              type="text"
              id="postalCode"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
          <div style={styles.inputGroup}>
            <label htmlFor="country" style={styles.formLabel}>
              Country
            </label>
            <input
              type="text"
              id="country"
              name="country"
              value={formData.country}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="phone" style={styles.formLabel}>
              Phone
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={styles.formControl}
            />
          </div>
        </div>
        <div style={styles.formGroup}>
          <div style={styles.inputGroup}>
            <label htmlFor="card-element" style={styles.formLabel}>
              Card Details
            </label>
            <CardElement stripe={stripePromise}>
              <CheckoutForm />
            </CardElement>
             </div>
        </div>
        <button type="submit" style={styles.btn} disabled={!stripe || loading}>
          {loading ? 'Processing...' : 'Pay Now'}
        </button>
       
        {error && <div style={styles.error}>{error}</div>}
      </form>
    </div>
 );
};

const styles = {
 container: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
 },
 form: {
    maxWidth: '900px',
    width: '100%',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
 },
 formGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
 },
 inputGroup: {
    flex: 1,
    marginRight: '10px',
 },
 formLabel: {
    fontWeight: 'bold',
    marginBottom: '5px',
    display: 'block',
 },
 formControl: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxSizing: 'border-box',
 },
 btn: {
    display: 'block',
    width: '100%',
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
 },
 error: {
    marginTop: '10px',
    color: '#dc3545',
    fontSize: '14px',
 },
};

export default PaymentForm;
