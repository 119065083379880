// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from "@mui/material/Modal";
import SupervisorRegistration from "../SupervisorRegistration";
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';







const SuperviserDashboard = () => {
  document.title = "SuperviserDashboard | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'locationType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Location Type',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openUserRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.locationType}`}</Button>
      )
    },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Email Address',
    //   cellClassName: 'gridCell',
    //   flex: 0.5,
    //   renderCell: cellValues => (
    //     <Button
    //       size='small'
    //       variant='text'
    //       color='info'
    //       onClick={(event) => {
    //         openUserRegistration(event,cellValues.row.id )
    //       }}
    //     >{`${cellValues.row.email}`}</Button>
    //   )
    // },
    {
      field: 'website',
      headerClassName: 'super-app-theme--header',
      headerName: 'Website',
      flex: 0.5
    },
    {
      field: 'startDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Start Date',
      flex: 0.3
    },
    {
      field: 'endDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'End Date',
      flex: 0.3
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      inActive: true,
                      
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Locations/AddLocations`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   
  ]

  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocations`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
  const columnstherapist = [
   
    {
      field: 'therapistId',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
     
 
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            const postData = {
                     
              inActive: true,
              id: cell.row.id
            }
            axios
            .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
              headers
            })
            .then(response => {
              setRefresh(!refresh)
            
            })
            .catch(error => {
              console.error('There was an error!', error)
            })
            // confirmAlert({
            //   message: 'Do you want to delete the record.',
            //   closeOnEscape: true,
            //   closeOnClickOutside: true,
            //   overlayClassName: 'overlay-custom-class-name',
            //   buttons: [
            //     {
            //       label: 'Yes',
            //       onClick: () => {
            //         const postData = {
                     
            //           inActive: true,
            //           id: cell.row.id
            //         }

            //         axios
            //           .post(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapist`, postData, {
            //             headers
            //           })
            //           .then(response => {
            //             setRefresh(!refresh)
                      
            //           })
            //           .catch(error => {
            //             console.error('There was an error!', error)
            //           })
            //       }
            //     },
            //     {
            //       label: 'No'
            //     }
            //   ]
            // })
          }}
        ></DeleteIcon>
      )
    }
  ]
  const columnsSupervisionGroups= [
   
    {
      field: 'therapistId',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
     
 
  
  ]
  const Appointments= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
        field: 'appointmentType',
        headerClassName: 'super-app-theme--header',
        headerName: 'Appointment Type',
        flex: 0.3
      },
    
 
  
  ]
  const ReviewHours= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
        field: 'appointmentType',
        headerClassName: 'super-app-theme--header',
        headerName: 'Appointment Type',
        flex: 0.3
      },
    
 
  
  ]
  const columnsBilling= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
        field: 'appointmentType',
        headerClassName: 'super-app-theme--header',
        headerName: 'Appointment Type',
        flex: 0.3
      },
    
 
  
  ]
  const columnsMessages= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      flex: 0.3
    },
    {
        field: 'appointmentType',
        headerClassName: 'super-app-theme--header',
        headerName: 'Appointment Type',
        flex: 0.3
      },
    
 
  
  ]
  const columnsIncidentReports= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'supervisorFirstName',
      headerClassName: 'super-app-theme--header',
      headerName: 'First Name',
      flex: 0.3
    },
    {
        field: 'supervisorLastName',
        headerClassName: 'super-app-theme--header',
        headerName: 'Last Name',
        flex: 0.3
      },
      {
        field: 'witnesses',
        headerClassName: 'super-app-theme--header',
        headerName: 'Witnesses',
        flex: 0.3
      },
    
    
 
  
  ]
  const columnsSupervisionAgendas= [
   
    {
      field: 'id',
      headerClassName: 'super-app-theme--header',
      headerName: 'ID',
      flex: 0.2
    },
    {
      field: 'supervisorFirstName',
      headerClassName: 'super-app-theme--header',
      headerName: 'First Name',
      flex: 0.3
    },
    {
        field: 'supervisorLastName',
        headerClassName: 'super-app-theme--header',
        headerName: 'Last Name',
        flex: 0.3
      },
     
    
    
 
  
  ]
  
  
  
  const openUserRegistration = (event, id = 0) => {
    navigate('/SitePageRegistration', { state: { uid: id } })
  }
  const _userId = localStorage.getItem('userid')
  const username = localStorage.getItem('UserName')

  
  const [openprofile, setOpenprofile] = React.useState(false);
  const handleCloseprofile = () => setOpenprofile(false);
  const handleOpenprofile = () => {
    setOpenprofile(true);
  };
  const [opentherapist, setOpentherapist] = React.useState(false);
  const [rowstherapist, setRowstherapist] = useState(null)

  const handleClosetherapist = () => setOpentherapist(false);
  const handleOpentherapist = () => {
    setOpentherapist(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorTherapist/supervisorTherapistList?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowstherapist(response)
      })
      .catch(error => {})

  };

  const [openSupervisionGroups, setSupervisionGroups] = React.useState(false);
  const [rowSupervisionGroups, setRowsSupervisionGroups] = useState(null)

  const handleCloseSupervisionGroups = () => setSupervisionGroups(false);
  const handleOpenSupervisionGroups = () => {
    setSupervisionGroups(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/SupervisorGroups/supervisorGroupList?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsSupervisionGroups(response)
      })
      .catch(error => {})

  };
  const [openAppointments, setAppointments] = React.useState(false);
  const [rowAppointments, setRowsAppointments] = useState(null)

  const handleCloseAppointments= () => setAppointments(false);
  const handleOpenAppointments = () => {
    setAppointments(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Appointment/getAppointmentByUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsAppointments(response)
      })
      .catch(error => {})

  };
  const [openReviewHours, setReviewHours] = React.useState(false);
  const [rowReviewHours, setRowsReviewHours] = useState(null)

  const handleCloseReviewHours= () => setReviewHours(false);
  const handleOpenReviewHours = () => {
    setReviewHours(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getTrackHoursUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsReviewHours(response)
      })
      .catch(error => {})

  };

  const [openBilling, setBilling] = React.useState(false);
  const [rowBilling, setRowsBilling] = useState(null)

  const handleCloseBilling= () => setBilling(false);
  const handleOpenBilling = () => {
    setBilling(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getTrackHoursUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsBilling(response)
      })
      .catch(error => {})

  };

  const [openMessages, setMessages] = React.useState(false);
  const [rowMessages, setRowsMessages] = useState(null)

  const handleCloseMessages= () => setMessages(false);
  const handleOpenMessages = () => {
    setMessages(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getMessagesByUserId?supervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsMessages(response)
      })
      .catch(error => {})

  };
  const [openIncidentReports, setIncidentReports] = React.useState(false);
  const [rowIncidentReports, setRowsIncidentReports] = useState(null)

  const handleCloseIncidentReports= () => setIncidentReports(false);
  const handleOpenIncidentReports = () => {
    setIncidentReports(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReportByUserId?userId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsIncidentReports(response)
      })
      .catch(error => {})

  };
  const [openSupervisionAgendas, setSupervisionAgendas] = React.useState(false);
  const [rowSupervisionAgendas, setRowsSupervisionAgendas] = useState(null)

  const handleCloseSupervisionAgendas= () => setSupervisionAgendas(false);
  const handleOpenSupervisionAgendas = () => {
    setSupervisionAgendas(true);
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Dashboard/getUserNotesBySuepervisorId?suepervisorId=${_userId}`, { headers })
      .then(response => {
        setLoading(false)
        setRowsSupervisionAgendas(response)
      })
      .catch(error => {})

  };
  
  
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1100,
    height: 500,
    bgcolor: "background.paper",
    border: "2px solid #356F60",
    boxShadow: 24,
    // overflow: "auto",
    // overflows: "hide",
    // zIndex: "10",
    customHeader: {
      backgroundColor: 'black', // Apply your desired background color
      color: 'white', // Apply the desired text color
      fontWeight: 'bold',
    },
  };
  const style1 = {
    position: 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #EA7772',
    boxShadow: 24,
    // overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
   
   
  }
  function handleCancelSupervisionAgendas() {
    navigate("/Agendas");
  }
  function handleCancelReviewHours() {
    navigate("/TrackHours");
  }
  function handleCancelMessages() {
    navigate("/Message");
  }
  function handleCancelIncidentReports() {
    navigate("/IncidentReporting");
  }
  return (
      <div className="page-content">
            <Container >
                {/* Profile Modal */}
            <Modal
                          open={openprofile}
                          onClose={handleCloseprofile}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style1}>
                          <Headroom >
                          <Grid
                                container
                                style={{ backgroundColor: '#356F60', position: 'sticky'}}
                              >
                                <Grid item xs={12} sm={12} xl={12}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      alignItems: 'left',
                                      justifyContent: 'space-between',
                                      height: '2rem'
                                    }}
                                  >
                                    <DialogTitle
                                      style={{
                                        flex: 1,
                                        // textAlign: 'left',
                                      }}
                                    >
                                      <h1
                                        style={{
                                          fontWeight: 'bold',
                                          // textAlign: 'left',
                                          marginTop:'-10px', 
                                          color: '#fff',
                                          textDecoration: 'underline',
                                          fontSize:'1rem'

                                        }}
                                      >
                                        Supervisor Registration

                                      </h1>
                                    </DialogTitle>
                                    <CancelOutlinedIcon
                                        onClick={handleCloseprofile}
                                      fontSize="large"
                                      color="red"
                                      style={{
                                        color: 'red',
                                        paddingRight: '5px',
                                        cursor: 'pointer',
                                        float:'right'
                                        // marginLeft: 'auto',
                                        // marginRight: '1.5rem'
                                      }}
                                    ></CancelOutlinedIcon>
                                  </div>
                                </Grid>
                              </Grid>
                         </Headroom>
                         <Grid item xs={12} sm={12} xl={12} >
                         <Card bordered={false} style={{ overflow: 'auto',
                      /* Other styles */
                      scrollbarWidth: 'thin', // For Firefox
                      scrollbarColor: '#356F60 transparent', // thumb color and track color
                      '&::-webkit-scrollbar': {
                        width: 8, /* Width of vertical scrollbar */
                        height: 8, /* Height of horizontal scrollbar */
                      },
                      '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#356F60', /* Color of the thumb */
                        borderRadius: 10,
                      },
                      '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent', /* Color of the track */
                      },
                      /* Adjust the width for the horizontal scrollbar */
                      '&::-webkit-scrollbar-thumb:horizontal': {
                        width: 4, /* Width of horizontal scrollbar */
                      },
                      // zIndex: "10",
            
                      height: '80vh' }} >
                           <SupervisorRegistration callingFrom ={'dashboard'} uid={_userId} handleCloseprofile={handleCloseprofile} >

                           </SupervisorRegistration>
                           </Card>
                            </Grid>

                         
                          </Box>
                        </Modal>
                        {/* therapist Modal */}
                        <Modal
                          open={opentherapist}
                          onClose={handleClosetherapist}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Therapist List

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleClosetherapist}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowstherapist === null || rowstherapist.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowstherapist}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnstherapist}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        {/* SupervisionGroups Modal */}
                        <Modal
                          open={openSupervisionGroups}
                          onClose={handleCloseSupervisionGroups}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Supervision Groups List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseSupervisionGroups}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowSupervisionGroups === null || rowSupervisionGroups.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowSupervisionGroups}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnsSupervisionGroups}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        {/* Appointments Modal */}
                        <Modal
                          open={openAppointments}
                          onClose={handleCloseAppointments}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Appointments List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseAppointments}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowAppointments === null || rowAppointments.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowAppointments}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={Appointments}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        {/*  ReviewHours Modal */}
                        <Modal
                          open={openReviewHours}
                          onClose={handleCloseReviewHours}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Review Hours List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseReviewHours}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowReviewHours === null || rowReviewHours.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowReviewHours}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={ReviewHours}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                         {/*  Billing Modal */}
                         <Modal
                          open={openBilling}
                          onClose={handleCloseBilling}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Billing List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseBilling}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowBilling === null || rowBilling.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowBilling}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnsBilling}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        {/*  Messages Modal */}
                        <Modal
                          open={openMessages}
                          onClose={handleCloseMessages}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Messages List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseMessages}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowMessages === null || rowMessages.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowMessages}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnsMessages}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        {/*  Incident Reports Modal */}
                        <Modal
                          open={openIncidentReports}
                          onClose={handleCloseIncidentReports}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Incident Reports List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseIncidentReports}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowIncidentReports === null || rowIncidentReports.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowIncidentReports}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnsIncidentReports}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                         {/*  Incident Reports Modal */}
                         <Modal
                          open={openSupervisionAgendas}
                          onClose={handleCloseSupervisionAgendas}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={style}>
                          <Headroom>
                          <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            textAlign: 'center',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Supervision Agendas List 

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseSupervisionAgendas}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
                </Grid>


                                  </Headroom>
                                  <Grid container style={{marginTop:'10px'}}>
                                    <Grid item xs={12} sm={12} xl={12}>
                                    {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rowSupervisionAgendas === null || rowSupervisionAgendas.length === 0 ? "Record not Found" : (
                    <DataGrid
                      rows={rowSupervisionAgendas}
                      classes={gridClasses}
                      rowHeight={25}
                      columns={columnsSupervisionAgendas}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}

                                    </Grid>
                                  </Grid>
             
            
             

                         
                          </Box>
                        </Modal>
                        
                       
             <Grid container spacing={1}>
             <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '18px',fontWeight:'bold',color:'black', }}>
                 Superviser Dashboard
                  </Typography>
                  
                </Grid>
                <Grid container spacing={2} style={{marginTop:'10px'}}>
                <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                <Grid item xs={6} sm={6} md={6} lg={6}>
                        <Card
                          sx={{
                            minWidth: 350,
                            marginBottom: "20px",
                            backgroundColor: "#377562",
                          }}
                        >
                          <CardContent>
                            {/* <Typography
                              variant="h5"
                              color="white"
                              gutterBottom
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                               My Profile
                             
                            </Typography> */}
                            <Typography>
                              <Button
                            //   className='Button'
                                style={{
                                //   backgroundColor: "antiquewhite",
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold'
                                }}
                                onClick={handleOpenprofile}
                              >
                                {/* {totalPatients} */}
                                My Profile
                              </Button>
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={3} sm={3} md={3} lg={3}></Grid>
                </Grid>
                
                    
                <Grid container spacing={2}>
                {/* <Grid item xs={3} sm={3} md={3} lg={3}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                         
                                style={{
                          
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                onClick={handleOpentherapist}
                              >
                               
                                Therapist List
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid> */}
                        {/* <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                onClick={handleOpenSupervisionGroups}
                              >
                                Supervision Groups
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid> */}
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                onClick={handleOpenAppointments}
                              >
                                Appointments
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                // onClick={handleOpenReviewHours}
                                onClick={handleCancelReviewHours}

                                
                              >
                                 Review Hours
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                // onClick={handleOpenBilling}
                              >
                                 Billing
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                      
                       
                      </Grid>
                      <Grid container spacing={2}  style={{marginTop:'20px'}}>
                     
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                // onClick={handleOpenMessages}
                                onClick={handleCancelMessages}

                                
                              >
                                 Messages
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                       
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                // onClick={handleOpenIncidentReports}
                                onClick={handleCancelIncidentReports}

                                
                              >
                                  Incident Reports
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                        <Card
                                sx={{
                                    height: 60,
                                    backgroundColor: "#377562",
                                }}
                                >
                                <CardContent>
                                    
                                    <Typography>
                              <Button
                                style={{
                                
                                color:'white',
                                  fontSize: "16Px",
                                  fontWeight:'bold',
                                  marginTop:'-8px'
                                }}
                                // onClick={handleOpenSupervisionAgendas}
                                onClick={handleCancelSupervisionAgendas}

                                
                                >
                                  {/* {totalPatients} */}
                                  Supervision Agendas
                              </Button>
                            </Typography>
                                </CardContent>
                                </Card>
                        </Grid>
                      
                      </Grid>


             </Grid>
            </Container>
          </div>
      
     
  )
}

export default SuperviserDashboard
