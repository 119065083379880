import { useState, useEffect,useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import TaskAltIcon from "@mui/icons-material/TaskAlt";

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';




const IncidentRegistration = props => {
  const fields = [
    "id",
    "practiceId",
    "supervisorFirstName",
    "supervisorLastName",
    "dateTime",
    "starTtime",
    "endTime",
    "incidentType",
    "supervisionType",
    "detailOfIncident",
    "witnesses",
    "supervisorFollowUp",
    "superviseeGoals",
    "therapistsId",
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    therapistsId:0,
    supervisorFirstName: "",
    supervisorLastName: "",
    dateTime: "",
    starTtime: "",
    endTime: "",
    incidentType: "",
    supervisionType: "",
    detailOfIncident: "",
    witnesses: "",
    supervisorFollowUp: "",
    superviseeGoals: "",
   
  };
  const [date, setDate] = React.useState(Moment().format('YYYY-MM-DD'))
 
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const Incidentid = props.uid;
  

  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const UserType = localStorage.getItem("User_Type")
  console.log('UserType',UserType)
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    supervisorLastName: Yup.string().required("Last Name is required"),
    supervisorFirstName: Yup.string().required("First Name is required"),
   
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const [approve, setapprove] = useState(false);

  const onSubmit = data => {
    
    let postData = data;
    setSubmitting(true);
    if(UserType == 'Therapist')
    {
      postData.pendingApproval= true
    }
    else{
      postData.approve= true
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/IncidentReport/AddIncidentReport`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);
          toast.success('Record saved successfully', { 
            position: "bottom-center",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        
            onClose: () => {
              props.handleClose()
             
            }

            });
          // alert.success("Record saved successfully.", {
          //   type: "success",
          //   onClose: () => {
          //     navigate("/IncidentReporting");
          //   }
          // });
        } 
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
 

 

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/IncidentReport/getIncidentReportById?id=${Incidentid}`, {
        headers
      })
      .then(response => {
        // setEmergencycontantid(response.id)
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.dateTime).format('YYYY-MM-DD')
        setDate(dateYMD)
        setapprove(response.approve)

        });
       
      })
      .catch(error => {});
  }, []);

  function handleCancel() {
    navigate("/IncidentReporting");
  }
 
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 4,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#377562',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  }
  const [TherapistID, setTherapistID] = useState("");
  const [TherapistList, setTherapistList] = useState([]);

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers?userType=${'Therapist'}`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.userName,
            value: field.id
          });
        });
        setTherapistList(prac);
        
      })
      .catch(error => {});

  }, []);
  const { selectedValue } = props;
  console.log('Muzammil', selectedValue)
  return (
    <React.Fragment>
       <Grid container>
        {/* <Box sx={style}> */}
         
        <Grid container  spacing={1}>
        
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                     Last Name
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                First Name
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

             
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                        name="supervisorLastName"
                        {...register("supervisorLastName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter last name"
                            {...field}
                            fullWidth
                            error={errors.supervisorLastName}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.supervisorLastName?.message}</p>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                        name="supervisorFirstName"
                        {...register("supervisorFirstName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="please enter first name"
                            {...field}
                            fullWidth
                            error={errors.supervisorFirstName}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.supervisorFirstName?.message}</p>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                Date
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                Time
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>


              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                        name="date"
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            type="date"
                            {...field}
                            fullWidth
                            value={date ? date : ''}
                            onChange={e => {
                            setValue('dateTime', e.target.value)
                            setDate(e.target.value)
                            }}
                          
                          />}
                      />
                  
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                        name="starTtime"
                        {...register("starTtime")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            type="time"
                            {...field}
                            fullWidth
                          />}
                      />
                
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                Incident Type
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                Therapist
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                {/* <Grid item xs={6} sm={6} xl={6}>
                <Typography style={{ fontSize: '14px' }}>
                Supervision Type
                  </Typography>
                </Grid> */}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Controller
                name='incidentType'
                margin='dense'
                {...register('incidentType')}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    style={{ marginTop: 0, marginBottom: 0 }}

                    {...field}
                    options={[
                      { value: 'Subpoena/Court', label: 'Subpoena/Court' },
                      { value: 'Ethics ', label: 'Ethics' },
                      { value: 'Site Incident', label: 'Site Incident' }
                    ]}
                    value={{ label: getValues('incidentType') }}
                    onChange={value => setValue('incidentType', value.label)}
                    size='small'
                  />
                )}
              />
                 </Grid>
                 <Grid item xs={5.5} sm={5.5} xl={5.5}>
                 <Controller
                    name="therapistsId"
                    // {...register("userId")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        {...field}
                        fullWidth
                        value={TherapistList.filter(function(option) {
                          return (
                            option.value === getValues("therapistsId")
                          );
                        })}
                        onChange={e => {
                            setValue("therapistsId", e.value);
                            setTherapistID(e.value);
                            
                           
                          }}
                        options={TherapistList}
                      />
                     
                    }
                  />
                 </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                 <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Details of Incident
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='detailOfIncident'
                {...register('detailOfIncident')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    placeholder='enter details of incident'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Witnesses
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='witnesses'
                {...register('witnesses')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    placeholder='enter details witnesses'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Supervisor Follow Up
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
               

                {UserType && UserType == 'Therapist'?(
                <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='supervisorFollowUp'
                {...register('supervisorFollowUp')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    readOnly={true}
                    minRows={3}
                    placeholder='enter supervisor follow up'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
                ):(
                <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>
                
                     <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='supervisorFollowUp'
                {...register('supervisorFollowUp')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    placeholder='enter supervisor follow up'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
                )}
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                 <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px' }}>
                Goals for Supervisee
                  </Typography>
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

               
                {UserType && UserType == 'Therapist'?(
                <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                 <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                name='superviseeGoals'
                {...register('superviseeGoals')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    readOnly={true}
                    minRows={3}
                    placeholder='enter goals for supervisee'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
                ):(
                <>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                    <Grid item xs={11} sm={11} xl={11}>
                    <Controller
                name='superviseeGoals'
                {...register('superviseeGoals')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={3}
                    placeholder='enter goals for supervisee'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '100px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                </>
                )}

               
               
                



              {UserType == 'Therapist'?(<>
                  {approve== true?(<>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                    <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px',marginBottom:'10px'}} >
                <Button
                className="Button"
                    // onClick={handleSubmit(onSubmitagenda)}
                    disabled={submitting}
                    
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                  
                  Save
                   <TaskAltIcon
                  style={{
                    color: "green",
                    // cursor: "not-allowed",
                  }}
                  >

                  </TaskAltIcon>
                   </Button>
                </Grid>
                  
                  </>):(<>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                    <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px',marginBottom:'10px'}} >
                <Button
                className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   {/* Send For Approved */}
                   Save
                   <TaskAltIcon
                  style={{
                    color: "red",
                    // cursor: "not-allowed",
                  }}
                  >

                  </TaskAltIcon>
                   </Button>
                </Grid>
                  
                  </>)}
              
                
                </>):(<>
              <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

                  <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px',marginBottom:'10px'}} >
                <Button
                className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                     {Incidentid > 0 ? "Approve" : "Save"}
                     
                   </Button>
                </Grid>
                
                </>)}

                {/* <Grid item xs={6} sm={6} xl={6} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid> */}
             

                  <Grid item xs={5.5} sm={5.5} xl={5.5} style={{marginTop:'10px'}}>
          <Button
          className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}

            >
             
              Cancel
            </Button>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5}> </Grid>

              </Grid>
             

          
        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default IncidentRegistration;
