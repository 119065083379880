import { useState, useEffect,useRef } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import InputMask from 'react-input-mask';
import RadioGroup from '@mui/material/RadioGroup'



import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Paper from '@mui/material/Paper'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Modal from '@mui/material/Modal';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { FileUploader } from "react-drag-drop-files";
import {  toast } from 'react-toastify';



const GrowthPlanRegistration = props => {

  const fields = [
    "id",
    "practiceId",
    "supervisorId",
    "growthPlanId",
    "firstName",
    "lastName",
    "conductRequiringRemediation",
    "ethicalRules",
    "remediationPlan",
    "educationalSteps",
  ];
  const defValues = {
    id: 0,
    practiceId: 0,
    supervisorId: 0,
    growthPlanId: 0,
    firstName: "",
    lastName: "",
    conductRequiringRemediation: "",
    ethicalRules: "",
    remediationPlan: "",
    educationalSteps: "",
  };
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const [Growthid, setGrowthid] = useState( props.callingFrom == 'Growth' ? props.Growthid: '')
 console.log('Growthid',Growthid)
  const validationSchema = Yup.object({
    // fullName: Yup.string().required("Full Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    
    // defaultPracticeId: Yup.array().required("Practice Name is required")
  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });

  const [completionDate, setcompletionDate] = React.useState(Moment().format('YYYY-MM-DD'))


  const onSubmit = data => {
    
    let postData = data;
    postData.supervisorId = Growthid
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/GrowthPlan/addGrowthPlan`,
        postData,
        { headers }
      )
      .then(response => {
        setSubmitting(false);

        toast.success("Record saved successfully.", {
          type: "success",
          autoClose: 1500, // Adjust the duration as needed
          // onClose: () => {
          //   navigate("/Supervisor");
          // }
        });
       
        window.location.reload();

      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };


  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_API_URL}/GrowthPlan/getGrowthPlanById?supervisorId=${Growthid}`, {
      headers
    })
      
      .then(response => {
        fields.forEach(field => {
          setValue(field, response[field]);
          var dateYMD = Moment(response.completionDate).format('YYYY-MM-DD')
          setcompletionDate(dateYMD)
        });
        
        
       
      })
      .catch(error => {});
  }, []);

  return (
    <React.Fragment>
        <Grid container  spacing={1}>
              <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
               Last Name
                  </Typography>
                </Grid>
                <Grid item xs={5.5} sm={5.5} xl={5.5}>
                <Typography style={{ fontSize: '14px' }}>
                First Name
                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                        <Grid item xs={5.5} sm={5.5} xl={5.5}>
                        <Controller
                        name="lastName"
                        {...register("lastName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please Enter Last Name"
                            {...field}
                            fullWidth
                            error={errors.lastName}
                          />}
                      />
                  <p style={{ color: 'red', fontSize: '14px' }}>{errors.lastName?.message}</p>

                        </Grid>
                        <Grid item xs={5.5} sm={5.5} xl={5.5}>
                        <Controller
                        name="firstName"
                        {...register("firstName")}
                        control={control}
                        render={({ field }) =>
                          <TextField
                            size="small"
                            placeholder="Please Enter First Name"
                            {...field}
                            fullWidth
                            // error={errors.locationName}
                          />}
                      />
                        </Grid>
                        <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px',textAlign:'center' }}>
                The following is identified as conduct requiring remediation in accordance with licensing rules <b style={{color:'black'}}> (LPC Supervisor outlines specific scenario)</b>:

                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='conductRequiringRemediation'
                {...register('conductRequiringRemediation')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Requiring Remediation'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px',textAlign:'center' }}>
                The following ethical rules are applicable in the aforementioned <b style={{color:'black'}}>(List specific Rules from Respective Ethical Board, if Ethics; other areas as noted)</b>:

                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='ethicalRules'
                {...register('ethicalRules')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Ethical Rules'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px',textAlign:'center' }}>
                The following Remediation Plan is implemented  <b style={{color:'black'}}>(Supervisor lists specific behaviors or steps that are taken, dates, and any other parties that are involved)</b>:

                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='remediationPlan'
                {...register('remediationPlan')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Remediation Plan'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>

                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={11} sm={11} xl={11}>
                <Typography style={{ fontSize: '14px',textAlign:'center' }}>
                The following educational steps are assigned to increase knowledge  <b style={{color:'black'}}>(LPC Supervisor lists specific training, or other educational materials to support the plan, if applicable)</b>:

                  </Typography>
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
               
                <Grid item xs={11} sm={11} xl={11}>
                <Controller
                name='educationalSteps'
                {...register('educationalSteps')}
                control={control}
                render={({ field }) => (
                  <TextareaAutosize
                    aria-label='minimum height'
                    minRows={5}
                    placeholder='Enter Educational Steps'
                    style={{
                      width: '100%',
                      borderRadius: '8px',
                      border: '2px solid #black',
                      height: '70px', // set a fixed height here
                    //   overflow: 'scroll'
                    }}
                    {...field}
                    fullWidth
                  />
                )}
              />
                </Grid>
                <Grid item xs={0.5} sm={0.5} xl={0.5}></Grid>
              
             

                
                <Grid item xs={12} sm={12} xl={12} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save
                   </Button>
                </Grid>
            
        </Grid>
     
    </React.Fragment>
  );
};
export default GrowthPlanRegistration;
