import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import "./i18n";

import {configureStore} from "./store/store";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import AlertMUITemplate from "react-alert-template-basic";

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe('pk_test_51OU7K2GzmgnXQM1ZzsvV9RUUBFbRKzol5julcMWC8zV8ckijoKAHbr1kBB2cwqbJuKN4kkxdomxe1fhpbNjkLDNm00DHUrBE3P');

const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  timeout: 1500,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AlertProvider template={AlertMUITemplate} {...options}>
  <Provider store={configureStore({})}>
    <React.Fragment>
      <BrowserRouter>
      <Elements stripe={stripePromise}>
       <App />
      </Elements>
       
      </BrowserRouter>
    </React.Fragment>
  </Provider>
  </AlertProvider>
);
reportWebVitals();
// serviceWorker.unregister();

