import { useState, useEffect } from "react";
import React from "react";
import ReactSelect from "react-select";
import axios from "axios";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Breadcrumbs from "../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import md5 from "md5";
import Moment from 'moment'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

import {
  Box,
  Button,
  Container,
  Stack,
  SvgIcon,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Select
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { useAlert } from "react-alert";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
// import { shallowEqual, useSelector, useDispatch } from 'react-redux'
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Multiselect from "multiselect-react-dropdown";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'


const TrackHoursRegistration = props => {
  const fields = [
    "id",
    "trackType",
    "activityType",
    "levelOne",
    "levelTwo",
    "locationSite",
    "trackDate",
    "trackTimeStart",
    "trackTimeEnd"

  ];
  const defValues = {
    id: 0,
    trackType: "",
    activityType: "",
    levelOne: "",
    levelTwo: "",
    locationSite: "",
    trackTime: "",
    trackDateStart: "",
    trackTimeEnd: "",

  };

  const [isLoading, setisLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [userName, setuserName] = useState("");
  console.log('userName', userName)
  const UsertypeName = localStorage.getItem('User_Type')

  const [userType, setuserType] = useState("");
  const [isPasswordChange, setIsPasswordChange] = useState(false);
  const [errorReceived, seterrorReceived] = useState("");
  const [zipDownloaded, setzipDownloaded] = useState(false);
  const [fetchedData, setfetchedData] = useState(false);
  const [userPractice, setUserPractice] = useState(0);
  const [userFacility, setUserFacility] = useState(0);
  const [rows, setRows] = useState(null);
  const [rows1, setRows1] = useState(null);
  const [Dob, setDob] = React.useState(Moment().format('YYYY-MM-DD'))
  const [Date, setDate] = React.useState(Moment().format('YYYY-MM-DD'))
  const [Time, setTime] = React.useState(Moment().format('hh:mm'))



  const [timerType, setTimerType] = React.useState("");
  const [DirectType, setDirectType] = React.useState("");
  const [level1, setlevel1] = React.useState("");
  const [level2, setlevel2] = React.useState("");




  const [userChildList, setUsersChildList] = React.useState([]);
  const [usersList, setUsersList] = React.useState([]);
  const [selectedUserIds, setselectedUserIds] = React.useState([]);
  const [selectUserError, setselectUserError] = useState(false);
  const alert = useAlert();
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const _userId = props.uid;


  // API Authanticatiion
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const validationSchema = Yup.object({
    activityType: Yup.string().required("Type is required"),

  });
  const {
    register,
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors }
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    resolver: yupResolver(validationSchema),
    defaultValues: defValues
  });
  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',

    },

  }
  const [approve, setapprove] = useState(false);

  const onSubmit = data => {
    setSubmitting(true);
    console.log('data', data)
    let postData = data;
    postData.userId = localStorage.getItem("userid")
    if (UsertypeName == 'Therapist') {
      postData.pendingApproval = true
    }
    else {
      postData.approve = true
    }
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/TrackHours/addTrackHours`,
        postData,
        { headers }
      )
      .then(response => {
        setSubmitting(false);
        toast.success('Record saved successfully', {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",

          onClose: () => {
            props.handleClose()

          }

        });
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/TrackHours/getTrackHoursById?id=${_userId}`, {
        headers
      })
      .then(response => {
        console.log("response.data: ", response);
        fields.forEach(field => {
          setValue(field, response[field]);
          setTimerType(response.trackType);
          setlevel1(response.levelOne);
          setlevel2(response.levelTwo);

          var dateYMD = Moment(response.trackDate).format('YYYY-MM-DD')
          setDate(dateYMD)
          var dateYMD = Moment(response.trackTime).format('hh:mm')
          setTime(dateYMD)
          setapprove(response.approve)



        });

      })
      .catch(error => { });
  }, []);

  function handleCancel() {
    navigate("/TrackHours");
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 400,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    p: 2,
    borderRadius: '10px',
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#377562',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  }
  const [timerhour, settimerhour] = useState([
    {
      type: 'direct', value: 'individual', label: 'individual'
    },
    {
      type: 'direct', value: 'Group', label: 'Group'
    },
    {
      type: 'direct', value: 'Family', label: 'Family'
    },
    {
      type: 'direct', value: 'Assessments', label: 'Assessments'
    },
    {
      type: 'direct', value: 'Couples', label: 'Couples'
    },
    {
      type: 'direct', value: 'Consultation w/clients', label: 'Consultation w/clients'
    },
    {
      type: 'direct', value: 'Speaking Engagement/Providing Training', label: 'Speaking Engagement/Providing Training'
    },
    {
      type: 'Indirect', value: 'Charting', label: 'Charting'
    },
    {
      type: 'Indirect', value: 'Assessment Review', label: 'Assessment Review'
    },
    {
      type: 'Indirect', value: 'Research', label: 'Research'
    },
    {
      type: 'Indirect', value: 'Workshops/Training', label: 'Workshops/Training'
    },
    {
      type: 'Indirect', value: 'Consultations w/other professionals', label: 'Consultations w/other professionals'
    },
    {
      type: 'Indirect', value: 'Supervision', label: 'Supervision'
    },

  ])
  const [timerhourtype, settimerhourtype] = useState([
    {
      level1: 'individual', level2: 'Infants', value: 'Infants', label: 'Infants'
    },
    {
      level1: 'individual', level2: 'Preschool', value: 'Preschool', label: 'Preschool'
    },
    {
      level1: 'individual', level2: 'School Age', value: 'School Age', label: 'School Age'
    },
    {
      level1: 'individual', level2: 'Adolescents', value: 'Adolescents', label: 'Adolescents'
    },
    {
      level1: 'individual', level2: 'Adults', value: 'Adults', label: 'Adults'
    },
    {
      level1: 'individual', level2: 'Older Adults', value: 'Older Adults', label: 'Older Adults'
    },
    {
      level1: 'Group', level2: 'Infants', value: 'Infants', label: 'Infants'
    },
    {
      level1: 'Group', level2: 'Preschool', value: 'Preschool', label: 'Preschool'
    },
    {
      level1: 'Group', level2: 'School Age', value: 'School Age', label: 'School Age'
    },
    {
      level1: 'Group', level2: 'Adolescents', value: 'Adolescents', label: 'Adolescents'
    },
    {
      level1: 'Group', level2: 'Adults', value: 'Adults', label: 'Adults'
    },
    {
      level1: 'Group', level2: 'Older Adults', value: 'Older Adults', label: 'Older Adults'
    },
    {
      level1: 'Family', level2: '', value: '', label: ''
    },
    {
      level1: 'Assessments', level2: 'Infants', value: 'Infants', label: 'Infants'
    },
    {
      level1: 'Assessments', level2: 'Preschool', value: 'Preschool', label: 'Preschool'
    },
    {
      level1: 'Assessments', level2: 'School Age', value: 'School Age', label: 'School Age'
    },
    {
      level1: 'Assessments', level2: 'Adolescents', value: 'Adolescents', label: 'Adolescents'
    },
    {
      level1: 'Assessments', level2: 'Adults', value: 'Adults', label: 'Adults'
    },
    {
      level1: 'Assessments', level2: 'Older Adults', value: 'Older Adults', label: 'Older Adults'
    },
    {
      level1: 'Couples', level2: '', value: '', label: ''
    },
    {
      level1: 'Consultation w/clients', level2: '', value: '', label: ''
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'Infants', value: 'Infants', label: 'Infants'
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'Preschool', value: 'Preschool', label: 'Preschool'
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'School Age', value: 'School Age', label: 'School Age'
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'Adolescents', value: 'Adolescents', label: 'Adolescents'
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'Adults', value: 'Adults', label: 'Adults'
    },
    {
      level1: 'Speaking Engagement/Providing Training', level2: 'Older Adults', value: 'Older Adults', label: 'Older Adults'
    },
    {
      level1: 'Charting', level2: '', value: '', label: ''
    },
    {
      level1: 'Assessment Review', level2: '', value: '', label: ''
    },
    {
      level1: 'Research', level2: '', value: '', label: ''
    },
    {
      level1: 'Workshops/Training', level2: '', value: '', label: ''
    },
    {
      level1: 'Consultations w/other professionals', level2: '', value: '', label: ''
    },
    {
      level1: 'Supervision', level2: '', value: '', label: ''
    },
  ])

  const [siteList, setsiteList] = useState([]);
  const [gernalList, setgernalList] = useState([]);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/GeneralItems/getGeneralItems`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.name,
            value: field.name
          });
        });
        setgernalList(prac);

      })
      .catch(error => { });

  }, []);
  useEffect(() => {
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocations`, {
        headers
      })
      .then(response => {
        var prac = [];
        response.forEach(field => {
          prac.push({
            label: field.locationType,
            value: field.locationType
          });
        });
        setsiteList(prac);

      })
      .catch(error => { });

  }, []);

  const { selectedValue } = props;
  const onSubmitreject = data => {

    let postData = data;
    setSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/TrackHours/addTrackHours`,
        postData,
        { headers }
      )
      .then(response => {
        if (response.id > 0) {
          setSubmitting(false);

          alert.success("Record saved successfully.", {
            type: "success",
            onClose: () => {
              props.handleClose()
            }
          });
        }
      })
      .catch(error => {
        setSubmitting(false);
        alert.error(`Error ${error.message}`, {
          type: "error"
        });
      });
  };
  return (
    <React.Fragment>
      <Grid container>
        {/* <Box sx={style}> */}

        <Grid container spacing={1} >
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11} style={{ textAlign: 'center', marginTop: '4px' }}>
            <Button
              //   className="Button"
              onClick={props.handleClose}
              disabled={submitting}
              fullWidth
              style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", backgroundColor: '#62A5A0', fontSize: '14px', fontWeight: 'bold', textAlign: 'center' }}

            >

              View Logs
            </Button>

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Select Site
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="locationSite"
              {...register("locationSite")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  {...field}
                  fullWidth
                  value={siteList.filter(function (option) {
                    return (
                      option.value === getValues("locationSite")
                    );
                  })}
                  onChange={e => {
                    setValue("locationSite", e.value);
                    // setgernalList(e.target.value)

                  }}
                  options={siteList}
                />

              }
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Activity
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="activityType"
              {...register("activityType")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  {...field}
                  fullWidth
                  value={gernalList.filter(function (option) {
                    return (
                      option.value === getValues("activityType")
                    );
                  })}
                  onChange={e => {
                    setValue("activityType", e.value);
                    // setgernalList(e.target.value)

                  }}
                  options={gernalList}
                  error={errors.activityType}
                />

              }
            />
            <p style={{ color: 'red', fontSize: '14px' }}>{errors.activityType?.message}</p>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Date
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="trackDate"
              // {...register("dob")}
              control={control}
              render={({ field }) =>
                <TextField
                  type='date'
                  size='small'
                  fullWidth
                  {...field}
                  value={Date ? Date : ''}
                  onChange={e => {
                    setValue('trackDate', e.target.value)
                    setDate(e.target.value)
                  }}
                // error={errors.trackDate}
                />
              }
            />
            {/* <p style={{ color: 'red', fontSize: '14px' }}>{errors.trackDate?.message}</p> */}

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Start Time
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="trackTimeStart"
              {...register("trackTimeStart")}
              control={control}
              render={({ field }) =>
                <TextField
                  type="time"
                  size="small"
                  {...field}
                  fullWidth
                // value={Time}
                // onChange={e => {
                //   setValue('trackTime', e.target.value)
                //   setTime(e.target.value)
                // }}
                />}
            />

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              End Time
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="trackTimeEnd"
              {...register("trackTimeEnd")}
              control={control}
              render={({ field }) =>
                <TextField
                  type="time"
                  size="small"
                  {...field}
                  fullWidth
                // value={Time}
                // onChange={e => {
                //   setValue('trackTime', e.target.value)
                //   setTime(e.target.value)
                // }}
                />}
            />

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Typography style={{ fontSize: '14px', color: 'black' }}>
              Activity Type
            </Typography>
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="trackType"
              margin="dense"
              {...register("trackType")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}
                  {...field}
                  size="small"
                  options={[
                    { value: "direct", label: "direct" },
                    { value: "Indirect", label: "Indirect" },

                  ]}
                  value={{ label: getValues("trackType") }}
                  onChange={value => {
                    setValue("trackType", value.value);
                    //   setuserType(value.value);
                    setTimerType(value.label);
                    setValue("levelOne", null);
                    setValue("levelTwo", null);
                  }}
                />}
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="levelOne"
              margin="dense"
              {...register("levelOne")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}
                  {...field}
                  size="small"
                  value={{ label: getValues("levelOne") }}
                  onChange={value => {
                    setValue("levelOne", value.value);
                    setlevel1(value.label);
                    setValue("levelTwo", null);
                  }}
                  options={
                    timerhour.filter(timerhour => timerhour.type === timerType)

                  }
                />}
            />
          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>
          <Grid item xs={11} sm={11} xl={11}>
            <Controller
              name="levelTwo"
              margin="dense"
              {...register("levelTwo")}
              control={control}
              render={({ field }) =>
                <ReactSelect
                  style={{ marginTop: 0, marginBottom: 0 }}
                  {...field}
                  size="small"
                  value={{ label: getValues("levelTwo") }}
                  onChange={value => {
                    setValue("levelTwo", value.value);
                    setlevel2(value.level2);
                  }}
                  options={
                    timerhourtype.filter(timerhourtype => timerhourtype.level1 === level1)

                  }
                />}
            />

          </Grid>
          <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

          {UsertypeName == 'Therapist' ? (<>
            {approve == true ? (<>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '10px', marginBottom: '5px' }} >
                <Button
                  className="Button"
                  // onClick={handleSubmit(onSubmit)}
                  disabled={submitting}

                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >

                  Approved
                  <TaskAltIcon
                    style={{
                      color: "green",
                      // cursor: "not-allowed",
                    }}
                  >

                  </TaskAltIcon>
                </Button>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


            </>) : (<>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

              <Grid item xs={11} sm={11} xl={11} style={{ marginTop: '10px', marginBottom: '5px' }} >
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Send For Approvel
                  <TaskAltIcon
                    style={{
                      color: "red",
                      // cursor: "not-allowed",
                    }}
                  >

                  </TaskAltIcon>
                </Button>
              </Grid>
              <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>


            </>)}


          </>) : (<>
            <Grid item xs={0.5} sm={0.5} xl={0.5} ></Grid>

            <Grid container xs={11} spacing={1} style={{padding:"10px"}}>

            <Grid item xs={4} lg={4}>
              <Button
                className="Button"
                onClick={handleSubmit(onSubmit)}
                disabled={submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                type="submit"
              >
                {_userId > 0 ? "Approve" : "Save"}
              </Button>
            </Grid>
          
            {_userId > 0 ? (

              <Grid item xs={4} lg={4}>
                <Button
                  className="Button"
                  onClick={handleSubmit(onSubmitreject)}
                  disabled={submitting}
                  fullWidth
                  style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}
                  type="submit"
                >
                  Reject
                </Button>
              </Grid>
            ) : ''}

            <Grid item xs={4} lg={4}>
              <Button
                className="Button"
                onClick={props.handleClose}
                disabled={submitting}
                fullWidth
                style={{ borderRadius: '10px', color: 'white', height: '40px', borderColor: "#EA7772", fontSize: '14px', fontWeight: 'bold' }}

              >

                Cancel
              </Button>
            </Grid>
            </Grid>

         


          </>)}


          {/* <Grid item xs={12} sm={12} xl={4} style={{marginTop:'10px'}}>
                <Button
          className="Button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={submitting}
                fullWidth
                 style={{borderRadius:'10px',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Save Hours
                   </Button>
                </Grid> */}

        </Grid>



        {/* </Box> */}
      </Grid>
    </React.Fragment>
  );
};
export default TrackHoursRegistration;
