import React, { useEffect, useRef, useState } from 'react'
import { Resizable } from 're-resizable'
import Button from '@mui/material/Button'
import { useHistory } from 'react-router-dom'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import { Grid } from '@mui/material'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Draggable from 'react-draggable'
// import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import VideoCallIcon from '@mui/icons-material/VideoCall'
import axios from 'axios'
import MinimizeIcon from '@mui/icons-material/Minimize'
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen'

// const history = createBrowserHistory({ forceRefresh: true })

const VideoComponent = (props) => {
    const token = localStorage.getItem('Token')
    const auth = `Bearer ${token}`
    const headers = {
      Authorization: auth
    }

  // Window Size find

  //Minimize Code Start

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  //Minimize Code End

  // console.log("Props my patient id is :", props)
  const { patientId } = props
  const [state, setState] = useState({ width: 320, height: 200 })
  const { width, height } = useState(windowSize)
  const [open, setOpen] = useState(false)
  const [minimized, setMinimized] = useState(false)
  const [openn, setOpenn] = useState(false)
  const [doc, setDoc] = useState(
    'https://dttmeetingroom.devforhealth.com/dttroomtestapp.html'
  )

  const handleopen = () => {
    setOpenn(true)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/updateRoomofSpecificPatient?patientId=${patientId}`,
        null,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log(response)
        } else {
        //   setApiError(response.data)
        }
      })
      .catch((error) => {})
  }

  const handleClose = () => {
    setOpenn(false)

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/Patients/meetingFinishedByProvider?patientId=${patientId}`,
        null,
        {
          headers,
        }
      )
      .then((response) => {
        if (response.id) {
          console.log(response)
        } else {
        //   setApiError(response.data)
        }
      })
      .catch((error) => {})
  }

  const handleMinimize = () => {
    setMinimized(true)
  }

  const styleDetailsPdf = {
    // width: '80%',
    // height: '85%',
    width: state.width,
    height: state.height,
    bgcolor: '#F0FFFF',
    position: 'absolute',
    backgroundColor: 'white',
    top: '94px',
    // left: '267px',
    // bottom: '100px',
    right: '100px',
    margin: 0,
    marginTop: 0,
    paddingBottom: '10px',
    padding: '0px',
    // overflow: 'auto',
    zIndex: '10',
    // tabIndex: "-1",
  }

  const minimizedStyle = {
    width: '200px',
    height: '100px',
    position: 'fixed',
    bottom: '20px',
    right: '10px',
    zIndex: '11',
    transition: 'all 0.3s ease',
  }

  //Resizeable Code

  const handleResizeStop = (e, direction, ref, d) => {
    var oldState = state

    setState({
      width: oldState.width + d.width,
      height: oldState.height + d.height,
    })
  }

  const resizableStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    outline: 'black',
    border: '1px solid black',
    padding: '16px',
    position: 'absolute',
  }

  return (
    <div className="page-content">
      {minimized ? (
        <div onClick={() => setMinimized(false)}>
          <Button
            variant="gradient"
            style={{
              marginTop: '-3px',
              marginBottom: '30px',
              float: 'right',
              marginLeft: 'auto',
              width: '90px',
              height: '30px',
              // backgroundColor: '#FCBE13',
              color: 'black',
              fontSize: '14px',
              cursor: 'pointer',
            }}
          >
            Maximize
          </Button>
        </div>
      ) : (
        <VideoCallIcon
          onClick={handleopen}
          style={{
            marginBottom: '10px',
            float: 'left',
            marginLeft: '20px',
            width: '40px',
            height: '25px',
            // backgroundColor: "#FCBE13",
            color: 'black',
            fontSize: '8px',
          }}
        />
      )}

      {minimized ? (
        ''
      ) : (
        <Modal
          open={openn}
          closeOnEscape={false}
          closeOnClickOutside={false}
          onClose={() => {}}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Grid xs={8}>
              <Box style={{ cursor: 'move' }}>
                <Draggable
                  bounds={{ left: -800, top: -100, right: 400, bottom: 500 }}
                >   
                  <Resizable
                    defaultSize={{ width: '80%', height: 600 }}
                    style={styleDetailsPdf}
                    onResizeStop={handleResizeStop}
                  >
                    <Box sx={{ resizableStyle }}>
                      {/* <div style = {{backgroundColor : 'Aqua'}} className="modal-title-bar"> */}

                  <Grid container style = {{backgroundColor : 'Aqua'}} >

                    <Grid item xs = {9}></Grid>
                    <Grid item xs = {3}>
                      <Button
                       onClick={handleClose}
                        variant="gradient"
                        // disabled={submitting}
                        style={{
                          marginTop: '10px',
                          float: 'right',
                          marginRight: '25px',
                          marginBottom : '5px',
                          width: '80px',
                          height: '35px',
                          backgroundColor: '#FCBE13',
                          color: 'black',
                          fontSize: '14px',
                        }}
                        // color="warning"
                        // size="small"

                        // startIcon={<img src={SaveIcon} style={{marginTop:'-3px',marginRight:'-6px',}}/>}
                      >
                        <CancelOutlinedIcon
                          fontSize="medium"
                          style={{
                            color: 'red',
                            paddingRight: '5px',
                            paddingBottom: '2px',
                          }}
                        ></CancelOutlinedIcon>
                        Close
                      </Button>
                    

                      <Button
                         onClick={handleMinimize}
                        variant="gradient"
                        // disabled={submitting}
                        style={{
                          marginTop: '10px',
                          float: 'right',
                          marginRight: '13px',
                          width: '100px',
                          height: '35px',
                          backgroundColor: '#FCBE13',
                          color: 'black',
                          fontSize: '14px',
                        }}
                       
                      >
                        <CloseFullscreenIcon
                          fontSize="medium"
                          style={{
                            color: 'red',
                            paddingRight: '5px',
                            paddingBottom: '2px',
                          }}
                        ></CloseFullscreenIcon>
                        Minimize
                      </Button>

                      </Grid>
                      </Grid>

                      {/* </div> */}

                      {openn ? (
                        <Grid
                          container
                          columnSpacing={1}
                          alignContent="center"
                          justifyContent="center"
                        >
                          <div
                            id="iframe_container"
                            style={{
                              position: 'absolute',
                              top: '3%',
                              // top: '15%',
                              bottom: '0%',
                              left: '0%',
                              right: '0%',
                              marginTop: '10px',
                              // marginBottom:"10px",
                            }}
                          >
                            <iframe
                              name="ifr"
                              // scrolling="yes"
                              fullWidth
                              style={{
                                position: 'absolute',
                                height: '100%',
                                width: '100%',
                              }}
                              src={`${doc}`}
                              frameBorder="0"
                              allow="microphone; camera"
                              allowFullScreen
                            />
                          </div>
                        </Grid>
                      ) : null}
                    </Box>
                  </Resizable>
                </Draggable>
              </Box>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  )
}

export default VideoComponent
