// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Controller, useForm } from 'react-hook-form';  
import { Grid, Typography,TextField } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Modal from '@mui/material/Modal';
import ReactSelect from "react-select";
import logoSm from "../../assets/images/maevisorylogo.png";
import InputMask from 'react-input-mask';

// import { confirmAlert } from 'react-confirm-alert' 
// import 'react-confirm-alert/src/react-confirm-alert.css' 
const Profile = () => {
  document.title = "Profile | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }
  const [providerType, setProviderType] = React.useState("");
  const [userType, setuserType] = useState("");


  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
//   const gridClasses = {
//     columnHeader: 'MuiDataGrid-columnHeaders',
//   };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'userName',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Name',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openUserRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.userName}`}</Button>
      )
    },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Email Address',
    //   cellClassName: 'gridCell',
    //   flex: 0.5,
    //   renderCell: cellValues => (
    //     <Button
    //       size='small'
    //       variant='text'
    //       color='info'
    //       onClick={(event) => {
    //         openUserRegistration(event,cellValues.row.id )
    //       }}
    //     >{`${cellValues.row.email}`}</Button>
    //   )
    // },
    {
      field: 'email',
      headerClassName: 'super-app-theme--header',
      headerName: 'Email Address',
      flex: 0.5
    },
    {
      field: 'fullName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Full Name',
      flex: 0.3
    },
    {
      field: 'userType',
      headerClassName: 'super-app-theme--header',
      headerName: 'User Type',
      flex: 0.3
    },
    {
      field: 'isActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Active',
      cellClassName: 'gridCell',
      sortable: false,
      type: 'boolean',
      flex: 0.2
    },
  
    // {
    //   field: 'delete',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Delete',
    //   sortable: true,
    //   flex: 0.1,
    //   renderCell: cell => (
    //     <DeleteIcon
    //       style={{
    //         marginRight: '5px',
    //         float: 'right',
    //         marginTop: '5px',
    //         color: 'red',
    //         cursor: 'pointer'
    //       }}
    //       onClick={cellValues => {
    //         confirmAlert({
    //           message: 'Do you want to delete the record.',
    //           closeOnEscape: true,
    //           closeOnClickOutside: true,
    //           overlayClassName: 'overlay-custom-class-name',
    //           buttons: [
    //             {
    //               label: 'Yes',
    //               onClick: () => {
    //                 const postData = {
    //                   email: cell.row.email,
    //                   fullName: cell.row.fullName,
    //                   userType: cell.row.userType,
    //                   facilityName: cell.row.facilityName,
    //                   id: cell.row.id,
    //                   deleted: true,
    //                   userId: cell.row.userId
    //                 }

    //                 axios
    //                   .post(`${process.env.NEXT_PUBLIC_API_URL}/Users/userRegistration`, postData, {
    //                     headers
    //                   })
    //                   .then(response => {
    //                     setRefresh(!refresh)
    //                   })
    //                   .catch(error => {
    //                     console.error('There was an error!', error)
    //                   })
    //               }
    //             },
    //             {
    //               label: 'No'
    //             }
    //           ]
    //         })
    //       }}
    //     ></DeleteIcon>
    //   )
    // }
  ]
  const {
    register,
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors }
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    // resolver: yupResolver(validationSchema),
    // defaultValues
  })
  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Users/getUsers`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  const openUserRegistration = (event, id = 0) => {
    navigate('/UserRegistration', { state: { uid: id } })
  }
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 300,
    height: 450,
    bgcolor: 'background.paper',
    border: '2px solid #EA7772',
    borderRadius: '20px',
    p: 4,
    overflow: 'auto',
    /* WebKit (Chrome, Safari) */
    '&::-webkit-scrollbar': {
      width: 8, /* Width of vertical scrollbar */
      height: 10,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#E48795',
      borderRadius: 10,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    /* Adjust the width for the horizontal scrollbar */
    '&::-webkit-scrollbar-thumb:horizontal': {
      width: 4, /* Width of horizontal scrollbar */
    },
    /* For Firefox */
    '&*': {
      scrollbarWidth: 'thin',
    },
  };
  
  return (
      <div className="page-content">
        
            <Container >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} xl={12}>
                <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <Grid container spacing={1}>
           <Grid item xs={12} sm={12} xl={4} style={{textAlign:'center'}}>
           <img src={logoSm} alt="logo-sm-light" height="100" style={{marginTop:'-27px'}}/>

           </Grid>
            <Grid item xs={12} sm={12} xl={4}>
                <Typography style={{ fontSize: '24px',fontWeight:'bold',textAlign:'center',marginTop:'-27px' }}>
                    My Profile
                  </Typography>
                </Grid>
              
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                     NAME
                  </Typography>
                </Grid>
               
                <Grid item xs={12} sm={8} xl={4}>
              <Controller
                name='name'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    name="name"
                    type="text"
                  />

                )}
              />
             
                </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                    DATE OF BIRTH
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
                <Controller
                    name='DOB'
                    // {...register('emailAddress')}
                    control={control}
                    render={({ field }) => (
                    <TextField
                        size='small'
                        fullWidth
                        {...field}
                        name="dob"
                        type="date"
                    />

                    )}
                />
                </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                    EMAIL
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
              <Controller
                name='emailAddress'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    name="email"
                    type="email"
                  />

                )}
              />
             
                 </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                    Phone 
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
                <InputMask
                mask='999-999-9999'
                disabled={false}
                // value={ssN}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('ssn', e.target.value)
                //   setssn(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter Phone No' />}
              </InputMask>
                </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                    Address
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
              <Controller
                name='address'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    name="address"
                    type="text"
                  />

                )}
              />
             
                 </Grid>
                 <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                    Emergency Contact
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
                <InputMask
                mask='999-999-9999'
                disabled={false}
                // value={ssN}
                style={{ height: '30px' }}
                onChange={e => {
                  setValue('ssn', e.target.value)
                //   setssn(e.target.value)
                }}
              >
                {() => <TextField id='outlined-basic' fullWidth size='small' placeholder='Enter Emergency  Phone No' />}
              </InputMask>
                </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                   License TYPE
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
                      <Controller
                    name="userType"
                    margin="dense"
                    {...register("userType")}
                    control={control}
                    render={({ field }) =>
                      <ReactSelect
                        style={{ marginTop: 0, marginBottom: 0 }}
                        {...field}
                        size="small"
                        options={[
                          { value: "LPC -A", label: "LPC -A" },
                          { value: "LMSW", label: "LMSW" },
                          { value: "LMFT -A", label: "LMFT -A" },
                        ]}
                        value={{ label: getValues("userType") }}
                        onChange={value => {
                          setValue("userType", value.value);
                          setuserType(value.value);
                          setProviderType(value.label);
                        }}
                      />}
                  />
                </Grid>
                <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                License Appoval
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
              <Controller
                name='address'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    name="address"
                    type="date"
                  />

                )}
              />
             
                 </Grid>
                 <Grid item xs={12} sm={4} xl={4}>
                <Typography style={{ fontSize: '14px' }}>
                License Expiration
                </Typography>
                </Grid>
                <Grid item xs={12} sm={8} xl={4}>
              <Controller
                name='address'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                    size='small'
                    fullWidth
                    {...field}
                    name="address"
                    type="date"
                  />

                )}
              />
             
                 </Grid>
                 <Grid item xs={12} sm={12} xl={12}>
                <Typography style={{ fontSize: '14px' }}>
               Site Details
                </Typography>
                </Grid>
                <Grid item xs={12} sm={12} xl={12}>
              <Controller
                name='address'
                // {...register('emailAddress')}
                control={control}
                render={({ field }) => (
                  <TextField
                  style={{borderRadius:'50px'}}
                    size='small'
                    fullWidth
                    {...field}
                    name="address"
                    type="text"
                  />

                )}
              />
             
                 </Grid>
                <Grid item xs={12} sm={12} xl={4}>
                <Button
                fullWidth
                 style={{borderRadius:'10px',backgroundColor:'#EA7772',color:'white',height:'40px',borderColor:"#EA7772",fontSize:'14px',fontWeight:'bold'}}
                  type="submit"
                 >
                   Update Profile
                   </Button>
                </Grid>

            </Grid>
         
        </Box>
      </Modal>

                </Grid>
                
            </Grid>
          
              <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                <Button sx={{ mb: 1 }} className="Button" onClick={handleOpen} variant='contained'>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
              </div>
              {/* <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? null : (
                    <DataGrid
                      rows={rows}
                  
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                   
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}
              </Box> */}
            </Container>
          </div>
      
     
  )
}

export default Profile
