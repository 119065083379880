// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography, NativeSelect } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import Modal from '@mui/material/Modal';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import Headroom from "react-headroom";
import DialogTitle from '@mui/material/DialogTitle';
import EducationRegistration from "../EducationRegistration";


const Education = (props) => {
  document.title = "Education | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const [Eduid, seteduid] = useState('')
  const [SEduid, setSEduid] = useState(props.SEduid !== undefined ? props.SEduid : 0)
  const [TEduid, setTEduid] = useState(props.TEduid !== undefined ? props.TEduid : 0)


  
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  function applyRefresh() {
    setRefresh(!refresh)
  }
  const navigate = useNavigate();

  const columns = [
    {
      field: 'sponsorName',
      headerClassName: 'super-app-theme--header',
      headerName: 'Sponsor Name',
      cellClassName: 'gridCell',
      flex: 0.7,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            seteduid(cellValues.row.id)
            // openIncidentRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.sponsorName}`}</Button>
      )
    },
    {
        field: 'course',
        headerClassName: 'super-app-theme--header',
        headerName: 'Course/Activity',
        flex: 0.5
      },
      {
        field: 'programTitle',
        headerClassName: 'super-app-theme--header',
        headerName: ' Program Title',
        flex: 0.5
      },
     
    {
      field: 'numberOfCes',
      headerClassName: 'super-app-theme--header',
      headerName: 'Number Of CES',
      flex: 0.5
    },

   
    
    // {
    //   field: 'isActive',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Delete',
    //   sortable: true,
    //   flex: 0.1,
    //   renderCell: cell => (
    //     <DeleteIcon
    //       style={{
    //         marginRight: '5px',
    //         float: 'right',
    //         marginTop: '5px',
    //         color: 'red',
    //         cursor: 'pointer'
    //       }}
    //       onClick={cellValues => {
    //         confirmAlert({
    //           message: 'Do you want to delete the record.',
    //           closeOnEscape: true,
    //           closeOnClickOutside: true,
    //           overlayClassName: 'overlay-custom-class-name',
    //           buttons: [
    //             {
    //               label: 'Yes',
    //               onClick: () => {
    //                 const postData = {
                     
    //                   inActive: true,
    //                   id: cell.row.id
    //                 }

    //                 axios
    //                   .post(`${process.env.REACT_APP_API_URL}/IncidentReport/AddIncidentReport`, postData, {
    //                     headers
    //                   })
    //                   .then(response => {
    //                     setRefresh(!refresh)
    //                     // setOpenNote(false)
    //                     // console.log('response.data: ', response.data)
    //                   })
    //                   .catch(error => {
    //                     console.error('There was an error!', error)
    //                   })
    //               }
    //             },
    //             {
    //               label: 'No'
    //             }
    //           ]
    //         })
    //       }}
    //     ></DeleteIcon>
    //   )
    // }
   
  ]

  const [rows, setRows] = useState(null)
 

 
  useEffect(() => {
    setLoading(true)
 
    axios
      .get(`${process.env.REACT_APP_API_URL}/EducationDetails/getEducationDetailsListById?supervisorId=${SEduid}&therapistId=${TEduid}`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})
    

  }, [refresh])
 
  const openIncidentRegistration = (event, id = 0) => {
    navigate('/EducationRegistration', { state: { Eid: id } })
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    height: 530,
    bgcolor: 'white',
    border: '2px solid #377562',
    boxShadow: 24,
    overflow: 'hidden',
    // p: 2,
    borderRadius: '8px',
    overflow:'auto',
    /* WebKit (Chrome, Safari) */
'&::-webkit-scrollbar': {
width: 8, /* Width of vertical scrollbar */
height: 10,
},
'&::-webkit-scrollbar-thumb': {
backgroundColor: '#377562',
borderRadius: 10,
},
'&::-webkit-scrollbar-track': {
backgroundColor: 'transparent',
},
/* Adjust the width for the horizontal scrollbar */
'&::-webkit-scrollbar-thumb:horizontal': {
width: 4, /* Width of horizontal scrollbar */
},
/* For Firefox */
'&*': {
scrollbarWidth: 'thin',
},
  }
  const [Education, setEducation] = React.useState(false);
  const handleOpenEducation = () => setEducation(true);
  
  const handleCloseEducation = () => {
    seteduid('')
    applyRefresh()
     setEducation(false)
  };
  useEffect(() => {
    if(Eduid>0)
    {
        handleOpenEducation()
    }
},[Eduid]);
  return (
      <div >
         <Modal
        open={Education}
        onClose={handleCloseEducation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
       <Grid container>
       <Grid item xs={12} sm={12} xl={12} > 
        <Headroom >
            <Grid
                  container
                  style={{ backgroundColor: '#356F60', position: 'sticky'}}
                >
                  <Grid item xs={12} sm={12} xl={12}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'left',
                        justifyContent: 'space-between',
                        height: '2rem'
                      }}
                    >
                      <DialogTitle
                        style={{
                          flex: 1,
                          // textAlign: 'left',
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: 'bold',
                            // textAlign: 'left',
                            marginTop:'-10px', 
                            color: '#fff',
                            textDecoration: 'underline',
                            fontSize:'1rem'

                          }}
                        >
                          Education Details

                        </h1>
                      </DialogTitle>
                      <CancelOutlinedIcon
                        onClick={handleCloseEducation}
                        fontSize="large"
                        color="red"
                        style={{
                          color: 'red',
                          paddingRight: '5px',
                          cursor: 'pointer',
                          float:'right'
                          // marginLeft: 'auto',
                          // marginRight: '1.5rem'
                        }}
                      ></CancelOutlinedIcon>
                    </div>
                  </Grid>
             

                </Grid>
              </Headroom>
              </Grid>
              <Grid item xs={12} sm={12} xl={12}>
                <EducationRegistration  Eduid ={Eduid} SEduid={SEduid} TEduid={TEduid} handleCloseEducation={handleCloseEducation} ></EducationRegistration>

                  </Grid>
       </Grid>
         
        </Box>
      </Modal>

            <Container >
              <Grid container spacing={1}>
                
                <Grid  item xs={12} sm={12} xl={12}>
                <Button  className="Button" onClick={handleOpenEducation} variant='contained' style={{fontSize:'14px',fontWeight:'bold', float:'right'
                  }}>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px',
                 
                  
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
                </Grid>
               <Grid item xs={12} sm={12} xl={12}>
               <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}
              </Box>
               </Grid>
              </Grid>
            </Container>
          </div>
      
     
  )
}

export default Education
