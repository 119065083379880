// ** React Imports
import { useState, useEffect } from 'react'
import axios from 'axios'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import React from 'react'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Grid, Typography } from '@mui/material'
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import "../Button.css"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const EducationDetails = () => {
  document.title = "EducationDetails | Maevisory";

  const [isLoading, setLoading] = useState(false)
  const getRowSpacing = React.useCallback(params => {
    return {
      top: params.isFirstVisible ? 0 : 0.5,
      bottom: params.isLastVisible ? 0 : 0.8
    }
  }, [])
  const token = localStorage.getItem('Token')
  const auth = `Bearer ${token}`
  const headers = {
    Authorization: auth
  }
  const gridStyle = {
    color: '#8392ab !important',
    textTransform: 'lowercase',
    fontSize: '13.6px',
    fontWeight: '400',
    '&:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px'
    }
  }

  const gridRowStyle = {
    boxShadow: 2,
    border: 2,
    borderRadius: 2,
    background: 'drak',
    fontSize: '13.4px !important',
    color: 'drak !important',
    borderColor: '#326C1D',
    '& .MuiDataGrid-cell:hover': {
      color: 'rgba(5, 152, 236, 0.637) !important',
      fontSize: '14.6px !important',
    },
    '& .super-app-theme--header': {
      backgroundColor: '#377562',
      borderRadius: 0.1,
      color: 'white',
      fontSize: '16px !important',
      
    }, 

  }
 
  const gridClasses = {
    columnHeader: 'MuiDataGrid-columnHeaders',
  };
  const [refresh, setRefresh] = useState(false)
  const navigate = useNavigate();

  const columns = [
    {
      field: 'locationType',
      headerClassName: 'super-app-theme--header',
      headerName: 'Location Type',
      cellClassName: 'gridCell',
      flex: 0.3,
      renderCell: cellValues => (
        <Button
          size='small'
          variant='text'
          color='info'
          onClick={(event) => {
            openUserRegistration(event,cellValues.row.id )
          }}
        >{`${cellValues.row.locationType}`}</Button>
      )
    },
    // {
    //   field: 'email',
    //   headerClassName: 'super-app-theme--header',
    //   headerName: 'Email Address',
    //   cellClassName: 'gridCell',
    //   flex: 0.5,
    //   renderCell: cellValues => (
    //     <Button
    //       size='small'
    //       variant='text'
    //       color='info'
    //       onClick={(event) => {
    //         openUserRegistration(event,cellValues.row.id )
    //       }}
    //     >{`${cellValues.row.email}`}</Button>
    //   )
    // },
    {
      field: 'website',
      headerClassName: 'super-app-theme--header',
      headerName: 'Website',
      flex: 0.5
    },
    {
      field: 'startDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'Start Date',
      flex: 0.3
    },
    {
      field: 'endDate',
      headerClassName: 'super-app-theme--header',
      headerName: 'End Date',
      flex: 0.3
    },
    {
      field: 'inActive',
      headerClassName: 'super-app-theme--header',
      headerName: 'Delete',
      sortable: true,
      flex: 0.1,
      renderCell: cell => (
        <DeleteIcon
          style={{
            marginRight: '5px',
            float: 'right',
            marginTop: '5px',
            color: 'red',
            cursor: 'pointer'
          }}
          onClick={cellValues => {
            confirmAlert({
              message: 'Do you want to delete the record.',
              closeOnEscape: true,
              closeOnClickOutside: true,
              overlayClassName: 'overlay-custom-class-name',
              buttons: [
                {
                  label: 'Yes',
                  onClick: () => {
                    const postData = {
                      id: cell.row.id,
                      inActive: true,
                      
                    }

                    axios
                      .post(`${process.env.REACT_APP_API_URL}/Locations/AddLocations`, postData, {
                        headers
                      })
                      .then(response => {
                        setRefresh(!refresh)
                        // setOpenNote(false)
                        // console.log('response.data: ', response.data)
                      })
                      .catch(error => {
                        console.error('There was an error!', error)
                      })
                  }
                },
                {
                  label: 'No'
                }
              ]
            })
          }}
        ></DeleteIcon>
      )
    }
   
  ]

  const [rows, setRows] = useState(null)
  useEffect(() => {
    setLoading(true)
    // POST request using axios inside useEffect React hook
    axios
      .get(`${process.env.REACT_APP_API_URL}/Locations/getLocations`, { headers })
      .then(response => {
        setLoading(false)
        setRows(response)
      })
      .catch(error => {})

    // empty dependency array means this effect will only run once (like componentDidMount in classes)
  }, [refresh])
 
  const openUserRegistration = (event, id = 0) => {
    navigate('/EducationRegistration', { state: { uid: id } })
  }

  return (
      <div className="page-content">
            <Container >
              <div style={{ width: 200, marginTop: '5px', marginBottom: '0px' }}>
                <Button sx={{ mb: 1 }} className="Button" onClick={openUserRegistration} variant='contained' style={{fontSize:'14px',fontWeight:'bold'}}>
                <AddOutlinedIcon
                fontSize='small'
                style={{
                  color: 'white',
                  paddingRight: '4px',
                  paddingBottom: '2px'
                }}
              ></AddOutlinedIcon>
                  Add New
                </Button>
              </div>
              <Box mb={0} style={{ marginTop: 0 }}>
              {isLoading ? (
            <div style={{ margin:'5rem', textAlign:'center' }}>
               <CircularProgress /> 
            </div>
          ) : (
                <div mb={0} style={{ height: 400, width: '100%' }}>
                  {rows === null || rows.length === 0 ? ("No Record Found") : (
                    <DataGrid
                      rows={rows}
                      classes={gridClasses}
                      rowHeight={20}
                      columns={columns}
                      getRowSpacing={getRowSpacing}
                      //checkboxSelection
                      fullWidth
                      sx={gridRowStyle}
                      pagination
                      autoPageSize
                    />
                  )}
                </div>
                )}
              </Box>
            </Container>
          </div>
      
     
  )
}

export default EducationDetails
